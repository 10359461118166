import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import EventBus from '../event-bus.js';
import utils from '@/Shared/utils';

import JointPaper from './vuecontrols/JointPaper';
import { Splitpanes, Pane } from 'splitpanes';

function toHex(n) {
    n = parseInt(n, 10);
    if (isNaN(n)) return "00";
    n = Math.max(0, Math.min(n, 255));
    return "0123456789ABCDEF".charAt((n - n % 16) / 16)
        + "0123456789ABCDEF".charAt(n % 16);
}

function rgbToHex(R, G, B) {
    return toHex(R) + toHex(G) + toHex(B)
}

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const port_defs_start = {
    position: {
        name: 'right'
    },
    attrs: {
        portBody: {
            magnet: true,
            r: 10,
            fill: '#E6A502',
            stroke: '#023047'
        }
    },
    label: {
        position: {
            name: 'right',
            args: { y: 12 }
        },
        markup: [{
            tagName: 'text',
            selector: 'label',
            className: 'label-text'
        }]
    },
    markup: [{
        tagName: 'circle',
        selector: 'portBody'
    }]
};

const port_defs_mediumfont = {
    position: {
        name: 'bottom'
    },
    attrs: {
        portBody: {
            magnet: true,
            r: 10,
            fill: '#E6A502',
            stroke: '#023047',
            strokeWidth: 0.5,
        }
    },
    label: {
        position: {
            name: 'bottom',
            args: { y: 12 }
        },
        markup: [{
            tagName: 'text',
            selector: 'label',
            className: 'label-text-medium'
        }]
    },
    markup: [{
        tagName: 'circle',
        selector: 'portBody'
    }]
};

const port_defs_smallfont = {
    position: {
        name: 'bottom'
    },
    attrs: {
        portBody: {
            magnet: true,
            r: 10,
            fill: '#E6A502',
            stroke: '#023047',
            strokeWidth: 0.5,
        }
    },
    label: {
        position: {
            name: 'bottom',
            args: { y: 12 }
        },
        markup: [{
            tagName: 'text',
            selector: 'label',
            className: 'label-text'
        }]
    },
    markup: [{
        tagName: 'circle',
        selector: 'portBody'
    }]
};

const DEF_W = 140;
const DEF_H = 80;

const DEF_X_SPACE = 260;
const DEF_Y_SPACE = 180;

Vue.component('menu-flow-chart', {
    mixins: [BaseComponent],
    components: {
        JointPaper,
        Splitpanes,
        Pane,
    },
    data: function () {
        return {
            ref_name: null,
            show_flypaper: false,
            fly_x: 0,
            fly_y: 0,

            projectID: null,
            projectName: null,

            file_menu_is_open: false,

            dialog_visible: false,
            dialog_width: '600',
            dialog_content: null,

            next_id: 1,
            selected_menu: -1,
            selected_tab: 0,
            loading: true,

			background: {
				color: '#ffffff'
			},
			gridSize: 1,
			drawGrid: false,
            model: null,
            environment: {
                ToolPalette: [
                    "Greeting",
                    "Menu",
                    "Enqueue",
                    "Announcement",
                    "DialByName",
                    "RingAll",
                    "HuntGroup",
                    "TransferExtension",
                    "TransferUser",
                    "TransferExternal",
                    "Voicemail",
                    "Custom",
                    "API",
                    "TrueOrFalse",
                    "SetValue",
                    "Hangup"
                ]
            },

            tabs: [],

            closing_tab: -1,

            internal_apis: {},
            properties_schema: null,
            properties_object: null,

            // documents
            greeting_docs: {},
            menu_docs: {},
            flow_icons: {},

            // graph items
            greetings: {},
            menus: {},

            selection: {
                active: false,
                start: { x: 0, y: 0 },
                end: { x: 0, y: 0 },
                items: [],
            }
        }
	},
    created() {
        this.ref_name = utils.generateUUID();

        if (this.controlData.FlowChartID)
            this.IDexpn = utils.compileObject(this, this.controlData.FlowChartID);

        if (this.controlData.EnvironmentID)
            this.envIDexpn = utils.compileObject(this, this.controlData.EnvironmentID);

        if (this.controlData.ReadOnlyExpression)
            this.readonlyexpn = utils.compileExpression(this, this.controlData.ReadOnlyExpression);
    },
    //Mounted Replaced with preRenderComplete
    computed: {
        InitialFlowChartID: function () {
            return this.IDexpn ? utils.evaluate(this.IDexpn, this) : false;
        },
        EnvironmentID: function () {
            return this.envIDexpn ? utils.evaluate(this.envIDexpn, this) : false;
        },
        readonly: function () {
            return this.readonlyexpn ? utils.evaluate(this.readonlyexpn, this) : false;
        },

        toolBar: function () {
            const h = this.$createElement;

            let loading;
            if (this.loading)
                loading = (
                    <v-progress-linear
                        indeterminate
                        color="yellow darken-2"
                    ></v-progress-linear>
                );

            return (
                <div>
                    {loading}
                    <div v-show={false} style="min-height: 60px; border: 1px solid silver; border-radius: 5px; margin: 3px;">
                    </div>
                </div>
            );
        },
        mainTabbedArea: function () {
            const h = this.$createElement;

            const content = (
                <JointPaper
                    class={{ [`c-FlowChartPaper_${this.name}`]: true }}
                    width="5000px"
                    height="5000px"
                    background={this.background}
                    grid-size={this.gridSize}
                    draw-grid={this.drawGrid}
                    default-link={this.defaultLink}
                    link-pinning={false}
                    validate-connection={this.validateConnection}
                    readonly={this.readonly}
                    on-init={(graph, paper) => this.setupGraph(graph, paper)}>
                </JointPaper>
            );

            const tab_titles = [];
            const tab_body = [];

            tab_titles.push(
                <v-tab key={0}>
                    Flowchart
                </v-tab>
            );

            tab_body.push(
                <v-tab-item key={0} style="height: 1px;" value={0}>
                    <div style="flex-grow: 1; height: 1px; overflow: auto; border: 1px solid silver;">
                        {content}
                    </div>
                </v-tab-item>
            );

            return ([
                <v-tabs value={0} style="flex-grow: 0;">
                    {tab_titles}
                </v-tabs>
                ,
                <v-tabs-items value={0} style="flex-grow: 1; height: 1px; display: flex; flex-direction: column;">
                    {tab_body}
                </v-tabs-items>
            ]);
        },
        toolPalette: function () {
            const h = this.$createElement;

            return (
                <div style="flex-grow: 1; height: 1px; overflow-y: auto; border: 1px solid silver;">
                    <JointPaper
                        class={{ [`c-FlowChartPaper_palette_${this.Name}`]: true }}
                        width="470px"
                        height="600px"
                        background={{ color: '#ffffff' }}
                        grid-size={1}
                        draw-grid={false}
                        readonly={true}
                        on-init={(graph, paper) => this.setupPalette(graph, paper)}>
                    </JointPaper>
                </div>
            );
        },
        properties: function () {
            const h = this.$createElement;

            if (!this.properties_schema)
                return <span>Nothing selected</span>;

            return (
                <property-grid
                    name="Noname"
                    root={this.Root}
                    parentType="VerticalStack"
                    schema={this.properties_schema}
                    form={["*"]}
                    cmodel={this.properties_object}
                    child={0}>
                </property-grid>
            );
        },

        topArea: function () {
            return this.toolBar;
        },
        mainArea: function () {
            return this.mainTabbedArea;
        },
        rightTopArea: function () {
            return this.toolPalette;
        },
        rightBottomArea: function () {
            return this.properties;
        },

        recentsMenu: function () {
            const h = this.$createElement;

            return [
                <v-list-item on-click={() => { this.file_menu_is_open = false; }}>
                    <v-list-item-content>
                        <v-list-item-title>
                            Last item 1
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-list-item on-click={() => { this.file_menu_is_open = false; }}>
                    <v-list-item-content>
                        <v-list-item-title>
                            Last item 2
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            ];
        },
        fileMenu: function () {
            const h = this.$createElement;

            return [
                <v-list-item key={0} on-click={() => { this.file_menu_is_open = false; this.graph.clear(); }}>
                    <v-list-item-icon>
                        <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            New
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-list-item key={1} on-click={() => { this.file_menu_is_open = false; }}>
                    <v-list-item-icon>
                        <v-icon>mdi-folder-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Open
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-divider></v-divider>,

                <v-list-item key={2} on-click={() => { this.file_menu_is_open = false; this.save(); }}>
                    <v-list-item-icon>
                        <v-icon>mdi-content-save</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Save
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-list-item key={3} on-click={() => { this.file_menu_is_open = false; this.saveAs(); }}>
                    <v-list-item-icon>
                        <v-icon>mdi-content-save-add</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Save As...
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,

                <v-divider></v-divider>,

                <v-menu
                    dense offset-x
                    scopedSlots={{
                        activator: ({ on, attrs }) =>
                            <v-list-item {...{ on }} {...{ attrs }}>
                                <v-list-item-icon>

                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        Recent
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        }}
                >
                    <v-list class="grey lighten-3" dense>
                        <v-list-item-group>
                            {this.recentsMenu}
                        </v-list-item-group>
                    </v-list>
                </v-menu>,

                <v-divider></v-divider>,

                <v-list-item key={4} on-click={() => { this.file_menu_is_open = false; }}>
                    <v-list-item-icon>
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Close
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>,
            ];
        },
        viewMenu: function () {
            const h = this.$createElement;

            return [
                <v-list-item key={0} on-click={() => { this.loadFlowchart(this.graph, this.paper); }}>
                    <v-list-item-icon>
                        <v-icon>mdi-refresh</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            Refresh
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            ];
        },
        topMenuBar: function () {
            const h = this.$createElement;

            const title = this.projectName || 'Unnamed Project';

            //<v-spacer></v-spacer>
            return (
                <div style="display: flex;">
                    <v-menu
                        offset-y
                        close-on-content-click={false}
                        value={this.file_menu_is_open}
                        on-input={(value) => this.file_menu_is_open = value}
                        scopedSlots={{
                            activator: ({ on, attrs }) =>
                                <v-btn elevation={0} text {...{ on }} {...{ attrs }}>
                                    File
                                </v-btn>
                        }}
                    >
                        <v-list class="grey lighten-3" dense>
                            <v-list-item-group value={this.selected_menu} on-change={() => this.selected_menu--}>
                                {this.fileMenu}
                            </v-list-item-group>
                        </v-list>
                    </v-menu>

                    <v-menu
                        offset-y
                        close-on-content-click={false}
                        scopedSlots={{
                            activator: ({ on, attrs }) =>
                                <v-btn elevation={0} text {...{ on }} {...{ attrs }}>
                                    View
                                </v-btn>
                        }}
                    >
                        <v-list class="grey lighten-3" dense>
                            <v-list-item-group value={this.selected_menu} on-change={() => this.selected_menu--}>
                                {this.viewMenu}
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
            );
        },

        flypaper: function () {
            const h = this.$createElement;

            let flypaper;
            if (this.show_flypaper) {
                const style = {
                    position: "fixed",
                    zIndex: "999",
                    opacity: ".7",
                    pointerEvent: "none",
                    left: `${this.fly_x}px`,
                    top: `${this.fly_y}px`,
                    maxWidth: `${DEF_W}px`,
                    maxHeight: `${DEF_H}px`,
                };
                flypaper = <div><div ref={this.ref_name} style={style} class="c-MenuFlowChart-FlyPaper"></div></div>;
            }

            return flypaper;
        },
    },
    methods: {
        preRenderComplete() {
            this.finishRenderHandler(this);
            this.Refresh();
        },
        async Refresh() {
            let id = this.InitialFlowChartID;
            let env = this.EnvironmentID;

            if (!id && this.Query('id'))
                id = this.Query('id');

            // Load the flowchart
            if (id)
                this.model = await utils.api.get(`Apps/Platform/Schema/FlowWidgets/v1/FlowChart?ID=${id}`);

            // Load the environment settings which includes the tools to be included in the tool palette
            if (env)
                this.environment = await utils.api.get(`Apps/Platform/Schema/FlowWidgets/v1/EnvironmentSettings?ID=${env}`);

            // Get the definitions for all the tool palette tools
            const tools = await utils.api.get(`Apps/Platform/Schema/FlowWidgets/v1/ToolDefinition/ListByTypeName`)

            this.tooldefinitions = {};

            // Store the tool definitions into an object keyed on the tool TypeName
            for (let i = 0; i < tools.length; i++) {
                const tool = tools[i];
                this.tooldefinitions[tool.TypeName] = tool;
                this.prepareTool(tool);
            }

            if (this.model) {
                this.projectID = this.model.ID;
                this.projectName = this.model.Name;
            }

            this.loading = false;
        },

        log(obj) {
            console.log(JSON.stringify(obj, getCircularReplacer(), 4))
        },

        blank_pointerdown(evt, x, y) {
            if (this.selected)
                this.selected.unhighlight(this.selected.el);

            this.selected = null;
            this.properties_schema = null;
            this.properties_object = null;

            //if (this.$menu_watch) {
            //    this.$menu_watch(); // Dispose of the previous watch
            //}
            if (this.$active_watches) {
                for (let w of this.$active_watches) w();
                this.$active_watches = [];
            }

            // Unhighlight previous selection
            if (this.selection.items.length > 0) {
                for (let i = 0; i < this.selection.items.length; i++) {
                    const item = this.selection.items[i];
                    this.$joint.highlighters.mask.remove(item.findView(this.paper));
                }
                this.selection.items = [];
            }

            this.selection.active = true;
            this.selection.shape = new this.$joint.shapes.standard.Rectangle();

            this.selection.offset = { x: evt.pageX - x, y: evt.pageY - y };
            this.selection.start = { x: x, y: y };
            this.selection.end = { x: x, y: y };

            this.selection.shape.position(x, y);
            this.selection.shape.resize(1, 1);
            this.selection.shape.attr({
                body: {
                    fillOpacity: '0',
                    stroke: '#cf4040',
                    strokeWidth: 0.5,
                    strokeDasharray: "5,5",
                },
                label: {
                    text: ''
                }
            });
            this.selection.shape.addTo(this.graph);

            document.body.addEventListener('mousemove', this.onSelectionMouseMove);
            document.body.addEventListener('mouseup', this.onSelectionMouseUp);
        },

        onSelectionMouseMove(evt) {
            if (this.selection.active) {
                this.selection.end = {
                    x: evt.pageX - this.selection.offset.x,
                    y: evt.pageY - this.selection.offset.y
                };

                this.selection.shape.resize(
                    this.selection.end.x - this.selection.start.x,
                    this.selection.end.y - this.selection.start.y
                );
            }
        },
        onSelectionMouseUp(evt) {
            this.selection.active = false;
            this.selection.shape.remove();

            document.body.removeEventListener('mousemove', this.onSelectionMouseMove);
            document.body.removeEventListener('mouseup', this.onSelectionMouseUp);

            // From the bounding box, find all shapes within
            const list = this.graph.findModelsInArea({
                x: this.selection.start.x,
                y: this.selection.start.y,
                width: this.selection.end.x - this.selection.start.x,
                height: this.selection.end.y - this.selection.start.y,
            });
            this.selection.items = this.selection.items.concat(list);

            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                const elementView = item.findView(this.paper);

                this.$joint.highlighters.mask.add(elementView, { selector: 'root' }, 'element-highlight', {
                    deep: true,
                    attrs: {
                        'stroke': '#FF4365',
                        'stroke-width': 3
                    }
                });
            }
        },

        onMultiselectMouseMove(evt) {
            const delta = {
                x: evt.pageX - this.selection.offset.x,
                y: evt.pageY - this.selection.offset.y,
            }
            this.selection.offset = { x: evt.pageX, y: evt.pageY };

            for (let i = 0; i < this.selection.items.length; i++) {
                const item = this.selection.items[i];
                if (item != this.selected.model)
                    item.translate(delta.x, delta.y);
            }
        },
        onMultiselectMouseUp(evt) {
            document.body.removeEventListener('mousemove', this.onMultiselectMouseMove);
            document.body.removeEventListener('mouseup', this.onMultiselectMouseUp);
        },

        async cell_pointerdown(cellView, evt, x, y) {
            if (this.selected)
                this.selected.unhighlight(this.selected.el);

            cellView.highlight(cellView.el);
            this.selected = cellView;

            // Unhighlight previous selection if the currently clicked on item is not in the selection list
            if (this.selection.items.length > 0 && !this.selection.items.find(a => a == cellView.model)) {
                for (let i = 0; i < this.selection.items.length; i++) {
                    const item = this.selection.items[i];
                    this.$joint.highlighters.mask.remove(item.findView(this.paper));
                }
                this.selection.items = [];
            }

            if (this.selection.items.length > 0) {
                // Prepare to move all items in selection
                this.selection.offset = { x: evt.pageX, y: evt.pageY };
                this.selection.start = { x: x, y: y };

                document.body.addEventListener('mousemove', this.onMultiselectMouseMove);
                document.body.addEventListener('mouseup', this.onMultiselectMouseUp);
            }

            let s;
            if (typeof cellView.model.attributes.Schema === 'string')
                s = await utils.schema.get(cellView.model.attributes.Schema, true);
            else if (typeof cellView.model.attributes.Schema === 'object')
                s = cellView.model.attributes.Schema;

            this.properties_schema = s;
            if (typeof cellView.model.attributes.Model === 'function')
                this.properties_object = cellView.model.attributes.Model();

            //if (this.$menu_watch) {
            //    this.$menu_watch(); // Dispose of the previous watch
            //}

            if (this.$active_watches) {
                for (let w of this.$active_watches) w();
                this.$active_watches = [];
            }

            //if (cellView.model.attributes.Type == 'Menu')
            //    this.$menu_watch = this.watchMenu(cellView.model, this.properties_object);
            //else if (cellView.model.attributes.Type == 'Greeting')
            //    this.$menu_watch = this.watchGreeting(cellView.model, this.properties_object);
            //else if (cellView.model.attributes.Type == 'Enqueue')
            //    this.$menu_watch = this.watchEnqueue(cellView.model, this.properties_object);
            //else
                this.$active_watches = this.watchAnyLabel(cellView.model, this.properties_object);
        },
        cell_pointerup(cellView, evt, x, y) {
            if (this.changed) {
                // After movement, we rebuild all the objects (maybe we now have more options)
            }

            if (typeof cellView.model.attributes.Model === 'function') {
                const m = cellView.model.attributes.Model();
                m.x = cellView.model.attributes.position.x;
                m.y = cellView.model.attributes.position.y;
                m.w = cellView.model.attributes.size.width;
                m.h = cellView.model.attributes.size.height;
            }

            this.changed = false;
        },
        cell_pointerdblclick(cellView, evt, x, y) {
            const method = cellView.model.attributes.Method;
            //const model = this.getMethodModel(method);
          
        },

        onMouseMove(evt) {
            this.fly_x = evt.pageX - this.offset.x;
            this.fly_y = evt.pageY - this.offset.y;
        },
        onMouseUp(evt) {
            const x = evt.pageX;
            const y = evt.pageY;
            const target = this.paper.$el.offset();

            // Dropped over paper ?
            if (x > target.left && x < target.left + this.paper.$el.width() && y > target.top && y < target.top + this.paper.$el.height()) {
                const s = this.flyShape.clone();
                this.dropShape(s, x - target.left - this.offset.x, y - target.top - this.offset.y);
            }

            document.body.removeEventListener('mousemove', this.onMouseMove);
            document.body.removeEventListener('mouseup', this.onMouseUp);
            this.flyShape.remove();
            this.flyShape = null;
            this.show_flypaper = false;
        },
        palette_pointerdown(cellView, evt, x, y) {
            this.show_flypaper = true;

            Vue.nextTick(() => {
                const flyGraph = new this.$joint.dia.Graph();
                const flyPaper = new this.$joint.dia.Paper({
                    el: this.$refs[this.ref_name],
                    model: flyGraph,
                    interactive: false
                });
                this.flyShape = cellView.model.clone();
                const pos = cellView.model.position();
                this.offset = {
                    x: x - pos.x,
                    y: y - pos.y
                };

                this.flyShape.position(0, 0);
                flyGraph.addCell(this.flyShape);

                this.fly_x = evt.pageX - this.offset.x;
                this.fly_y = evt.pageY - this.offset.y;

                document.body.addEventListener('mousemove', this.onMouseMove);
                document.body.addEventListener('mouseup', this.onMouseUp);

            });
            
            /*
            $('body').append('<div id="flyPaper" style="position:fixed;z-index:100;opacity:.7;pointer-event:none;"></div>');
            var flyGraph = new joint.dia.Graph,
                flyPaper = new joint.dia.Paper({
                    el: $('#flyPaper'),
                    model: flyGraph,
                    interactive: false
                }),
                flyShape = cellView.model.clone(),
                pos = cellView.model.position(),
                offset = {
                    x: x - pos.x,
                    y: y - pos.y
                };

            flyShape.position(0, 0);
            flyGraph.addCell(flyShape);
            $("#flyPaper").offset({
                left: e.pageX - offset.x,
                top: e.pageY - offset.y
            });
            $('body').on('mousemove.fly', function (e) {
                $("#flyPaper").offset({
                    left: e.pageX - offset.x,
                    top: e.pageY - offset.y
                });
            });
            $('body').on('mouseup.fly', function (e) {
                var x = e.pageX,
                    y = e.pageY,
                    target = paper.$el.offset();

                // Dropped over paper ?
                if (x > target.left && x < target.left + paper.$el.width() && y > target.top && y < target.top + paper.$el.height()) {
                    var s = flyShape.clone();
                    s.position(x - target.left - offset.x, y - target.top - offset.y);
                    graph.addCell(s);
                }
                $('body').off('mousemove.fly').off('mouseup.fly');
                flyShape.remove();
                $('#flyPaper').remove();
            });
            */
        },

        async dropShape(shape, x, y) {
            // s.attributes.Schema -> resolve and get default model for
            // s.attributes.Model -> assign to above generated model

            let schema = shape.attributes.Schema;
            if (typeof schema === 'string') {
                schema = await utils.schema.get(schema, true);
            }
            const m = utils.schema.getDefaultModel(schema);
            m.Name = 'Unnamed';
            m.x = x; m.y = y;
            m.w = DEF_W; m.h = DEF_H;

            shape.prop({ Model: () => m });
            shape.position(x, y);
            this.graph.addCell(shape);

            //Vue.set(this.menu_docs, m.Name, m);
            Vue.set(this.flow_icons, shape.id, m); // To ensure Vue makes the model reactive

            const tool = this.tooldefinitions[shape.attributes.Type];
            if (!tool || !tool.Ports || tool.Ports.length < 1 || tool.PortGroup == 'none')
                return;

            this.prepareTool(tool);

            this.setupGenericWidgetPorts(shape, tool, m);
        },

        async getSchemaForCLRType(type) {
            switch (type) {
                case 'string':
                case 'System.String':
                    return {
                        type: 'string',
                    };
                case 'bool':
                case 'System.Boolean':
                    return {
                        type: 'boolean',
                    };
                case 'float':
                case 'double':
                case 'System.Float':
                case 'System.Double':
                    return {
                        type: 'number',
                    };
                case 'int':
                case 'long':
                case 'System.Int16':
                case 'System.Int32':
                case 'System.Int64':
                    return {
                        type: 'integer',
                    };
                case 'object':
                case 'CompilerCore.Evaluators.JsonObj':
                    return {
                        type: 'object',
                    };
                case 'DateTime':
                case 'System.DateTime':
                    return {
                        type: 'string',
                        format: 'date-time',
                    };

                default:
                    return await utils.api.get(`Apps/WebFlowHelpers/GetIconSchema?Type=${encodeURIComponent(type)}`);
            }
        },
        async getSchemaForUserType(ownerid, type, projectName) {
            return { type: 'object', properties: {} };
        },

        setupGraph(graph, paper) {
            const c = this;
            this.graph = graph;
            this.paper = paper;

            const removeButton = new this.$joint.linkTools.Remove();
            const targetArrowheadTool = new this.$joint.linkTools.TargetArrowhead({
                focusOpacity: 0.5
            });

            const linktoolsView = new this.$joint.dia.ToolsView({
                tools: [removeButton, targetArrowheadTool ]
            });

            const e_boundaryTool = new this.$joint.elementTools.Boundary();
            const e_removeButton = new this.$joint.elementTools.Remove({ x: '50%', y: '0' });

            const elementtoolsView = new this.$joint.dia.ToolsView({
                tools: [ e_boundaryTool, e_removeButton ]
            });

            const e_boundaryTool2 = new this.$joint.elementTools.Boundary();
            const e_removeButton2 = new this.$joint.elementTools.Remove({ x: '50%', y: '0' });
            const e_enqueueButton = new this.$joint.elementTools.Button({
                markup: [{
                    tagName: 'circle',
                    selector: 'button',
                    title: 'markup title',
                    attributes: {
                        'r': 14,
                        'fill': '#001DFF',
                        'cursor': 'pointer',
                        
                    }
                }, {
                    tagName: 'path',
                    selector: 'icon',
                    title: 'attribute title',
                    attributes: {
                        'd': 'M -2 4 2 4 M 0 3 0 0 M -2 -1 1 -1 M -1 -4 1 -4',
                        'transform': 'scale(1.5)',
                        'fill': 'none',
                        'stroke': '#FFFFFF',
                        'stroke-width': 2,
                        'pointer-events': 'none',
                    }
                }],
                x: '20%',
                y: '20%',
                    title: 'base title',
                offset: {
                    x: 0,
                    y: 0
                },
                rotate: true,
                action: function (evt, elementView) {
                    const q = elementView.model.attributes.Model();
                    
                    utils.executeAndCompileAllActions(c.controlData.NavigateQueueActions, { Data: q }, c);
                }
            });

            const enqueuetoolsView = new this.$joint.dia.ToolsView({
                tools: [e_boundaryTool2, e_removeButton2, e_enqueueButton]
            });

            //const e_boundaryTool2 = new this.$joint.elementTools.Boundary();
            //const e_removeButton2 = new this.$joint.elementTools.Remove({ x: '50%', y: '0' });

            //const entryButton = new this.$joint.elementTools.Button({
            //    markup: [{
            //        tagName: 'circle',
            //        selector: 'button',
            //        attributes: {
            //            'r': 7,
            //            'fill': '#001DFF',
            //            'cursor': 'pointer'
            //        }
            //    }, {
            //        tagName: 'path',
            //        selector: 'icon',
            //        attributes: {
            //            'd': 'M -2 4 2 4 M 0 3 0 0 M -2 -1 1 -1 M -1 -4 1 -4',
            //            'fill': 'none',
            //            'stroke': '#FFFFFF',
            //            'stroke-width': 2,
            //            'pointer-events': 'none'
            //        }
            //    }],
            //    x: '100%',
            //    y: '100%',
            //    offset: {
            //        x: 0,
            //        y: 0
            //    },
            //    rotate: true,
            //    action: function (evt) {
            //        alert('This is the entry point for the flowchart!');
            //    }
            //});
            //const elementStartToolsView = new this.$joint.dia.ToolsView({
            //    tools: [entryButton, e_boundaryTool2, e_removeButton2]
            //});


            if (!this.readonly) {
                paper.on('link:mouseenter', function (linkView) {
                    linkView.addTools(linktoolsView);
                });

                paper.on('link:mouseleave', function (linkView) {
                    linkView.removeTools();
                });

                paper.on('element:mouseenter', function (elementView) {
                    switch (elementView.model.attributes.Type) {
                        case 'Enqueue':
                            elementView.addTools(enqueuetoolsView);
                            break;

                        default:
                            elementView.addTools(elementtoolsView);
                            break;
                    }
                });

                paper.on('element:mouseleave', function (elementView) {
                    elementView.removeTools();
                });
            }

            paper.on('blank:pointerdown', this.blank_pointerdown);

            paper.on('cell:pointerdown', this.cell_pointerdown);
            paper.on('cell:pointerup', this.cell_pointerup);
            paper.on('cell:pointerdblclick', this.cell_pointerdblclick);

            graph.on('change:position change:size change:source change:target change:vertices add remove', function (cell) {
                c.changed = true;
            });

            this.loadFlowchart2(graph, paper);
        },
        setupPalette(graph, paper) {
            const c = this;
            this.graph_palette = graph;
            this.paper_palette = paper;

            let x = 10;
            let y = 10;
            for (let i = 0; i < this.environment.ToolPalette.length; i++) {
                const toolName = this.environment.ToolPalette[i];
                const tool = this.tooldefinitions[toolName];
                //const func = `add${tool}Widget`;

                if (i % 3 == 0) {
                    x = 10;
                    if (i >= 3)
                        y += DEF_H + 10;
                }

                this.addGenericWidget(graph, paper, toolName, tool.Tooltip, x, y, DEF_W, DEF_H)
                //this[func](graph, paper, tool, x, y, DEF_W, DEF_H);

                x += DEF_W + 10;
            }
            /*
            this.addGreetingWidget(graph, paper, 'Greeting', 10, 10, DEF_W, DEF_H);
            this.addMenuWidget(graph, paper, 'Menu', 10 + DEF_W + 10, 10, DEF_W, DEF_H);
            this.addEnqueueWidget(graph, paper, 'Enqueue', 10 + DEF_W + DEF_W + 10 + 10, 10, DEF_W, DEF_H);

            this.addDialByNameWidget(graph, paper, 'Dial-by-Name', 10, 10 + DEF_H + 10, DEF_W, DEF_H);
            this.addRingAllWidget(graph, paper, 'Ring-All', 10 + DEF_W + 10, 10 + DEF_H + 10, DEF_W, DEF_H);
            this.addTransferExternalWidget(graph, paper, 'Transfer External', 10 + DEF_W + DEF_W + 10 + 10, 10 + DEF_H + 10, DEF_W, DEF_H);

            this.addCustomWidget(graph, paper, 'Custom Flow', 10, 10 + 2*DEF_H + 2*10, DEF_W, DEF_H);
            this.addVoicemailWidget(graph, paper, 'Voicemail', 10 + DEF_W + 10, 10 + 2 * DEF_H + 2 * 10, DEF_W, DEF_H);
            this.addAPIWidget(graph, paper, 'API', 10 + DEF_W + DEF_W + 10 + 10, 10 + 2 * DEF_H + 2 * 10, DEF_W, DEF_H);

            this.addTrueOrFalseWidget(graph, paper, 'True/False', 10, 10 + 3 * DEF_H + 3 * 10, DEF_W, DEF_H);
            this.addHangupWidget(graph, paper, 'Hang Up', 10 + DEF_W + 10, 10 + 3 * DEF_H + 3 * 10, DEF_W, DEF_H);
            this.addAnnouncementWidget(graph, paper, 'Announce', 10 + DEF_W + DEF_W + 10 + 10, 10 + 3 * DEF_H + 3 * 10, DEF_W, DEF_H);

            this.addStartHereWidget(graph, paper, 'Start', 10, 10 + 4 * DEF_H + 4 * 10, 2 * DEF_W / 3, DEF_H);
            this.addPhoneWidget(graph, paper, 'Phone', 10 + DEF_W + 10, 10 + 4 * DEF_H + 4 * 10, 2 * DEF_W / 3, DEF_H);
            */
            paper.on('cell:pointerdown', this.palette_pointerdown);
        },

        //// New FlowWidgets 2.0 ////

        linkItems(graph, paper, m1, target, port) {
            const src = m1.attributes.ports.items.find(p => p.attrs.label.text == port);
            const src_port = {
                id: m1.id,
                magnet: 'portBody',
                port: src.id,
            };
            this.addLink(graph, paper, src_port, target, port);
        },

        async loadFlowchart2(graph, paper) {
            graph.clear();

            // Clear references to pre-existing graph items
            this.greetings = {};
            this.menus = {};

            if (!this.model)
                return;

            const idlookup = {};
            const lookupid = {};

            // Step 1. Add all icons to the graph
            for (let i = 0; i < this.model.Graph.length; i++) {
                const item = this.model.Graph[i];
                let m1 = this.addGenericWidget(graph, paper, item.Type, item.Model, item.Model.x, item.Model.y, item.Model.w, item.Model.h);
                if (!m1)
                    continue;

                idlookup[m1.id] = item;
                lookupid[item.Id] = m1;
            }

            // Step 2. Add links between icons
            for (let i = 0; i < this.model.Graph.length; i++) {
                const item = this.model.Graph[i];

                // Locate the newly created shape for this item
                const m1 = lookupid[item.Id];
                const tool = this.tooldefinitions[item.Type];
                if (!tool || !tool.Ports || !tool.Ports.length || tool.PortGroup == 'none')
                    continue;

                for (let j = 0; j < tool.Ports.length; j++) {
                    const p = tool.Ports[j];
                    switch (p.Type) {
                        case 'Fixed':
                            if (item.Model[p.IdField]) {
                                const target = lookupid[item.Model[p.IdField]];
                                if (target) this.linkItems(graph, paper, m1, target, p.Label);
                            }
                            break;

                        case 'Dynamic':
                            if (p.Condition_Func(item.Model) && item.Model[p.IdField]) {
                                const target = lookupid[item.Model[p.IdField]];
                                if (target) this.linkItems(graph, paper, m1, target, p.Label);
                            }
                            break;

                        case 'DynamicList':
                            const array = p.ArrayField_Func(item.Model);
                            if (typeof array === 'object' && Array.isArray(array))
                                for (let k = 0; k < array.length; k++) {
                                    const opt = array[k];
                                    if (p.ArrayElementCondition_Func(item.Model, opt) && opt[p.ArrayElementIdField] && p.ArrayElementLabelExpression_Func(item.Model, opt)) {
                                        const target = lookupid[opt[p.ArrayElementIdField]];
                                        if (target) this.linkItems(graph, paper, m1, target, p.ArrayElementLabelExpression_Func(item.Model, opt));
                                    }
                                }
                            break;
                    }
                }
            }
        },

        prepareTool(tool) {
            for (let i = 0; i < tool.Ports.length; i++) {
                const p = tool.Ports[i];

                switch (p.Type) {
                    case 'Dynamic':
                        if (!p.Condition_Func)
                            p.Condition_Func = new Function('Model', `return ${p.Condition};`);
                        break;

                    case 'DynamicList':
                        if (!p.ArrayField_Func)
                            p.ArrayField_Func = new Function('Model', `return ${p.ArrayField};`);
                        if (!p.ArrayElementLabelExpression_Func)
                            p.ArrayElementLabelExpression_Func = new Function('Model', 'Item', `return ${p.ArrayElementLabelExpression};`);
                        if (!p.ArrayElementCondition_Func)
                            p.ArrayElementCondition_Func = new Function('Model', 'Item', `return ${p.ArrayElementCondition};`);

                        break;
                }
            }
        },
        setupGenericWidgetPorts(m1, tool, model) {
            const portList = [];

            for (let i = 0; i < tool.Ports.length; i++) {
                const p = tool.Ports[i];

                switch (p.Type) {
                    case 'Fixed':
                        portList.push({ group: 'out', attrs: { label: { text: p.Label } } });
                        break;

                    case 'Dynamic':
                        {
                            if (p.Condition_Func(model))
                                portList.push({ group: 'out', attrs: { label: { text: p.Label } } });
                        }
                        break;

                    case 'DynamicList':
                        {
                            const array = p.ArrayField_Func(model);
                            if (typeof array === 'object' && Array.isArray(array))
                                for (let j = 0; j < array.length; j++) {
                                    const item = array[j];
                                    if (p.ArrayElementCondition_Func(model, item))
                                        portList.push({ group: 'out', attrs: { label: { text: p.ArrayElementLabelExpression_Func(model, item) } } });
                                }
                        }
                        break;
                }
            }

            m1.addPorts(portList);
        },

        addGenericWidget(graph, paper, typeName, model, x, y, w, h) {
            const tool = this.tooldefinitions[typeName];
            if (!tool)
                return;

            this.prepareTool(tool);

            let m1;
            if (tool.PortGroup == 'none')
                m1 = new this.$joint.shapes.standard[tool.Shape]();
            else
                m1 = new this.$joint.shapes.standard[tool.Shape]({
                    ports: {
                        groups: {
                            'out': tool.PortGroup == 'smallfont' ? port_defs_smallfont : tool.PortGroup == 'mediumfont' ? port_defs_mediumfont : null,
                        }
                    }
                });

            let title;
            if (model && typeof model === 'object') {
                title = model[tool.LabelField];
            }
            else if (model && typeof model === 'string') {
                title = model;
            }

            m1.position(x, y);
            m1.resize(w, h)
            m1.attr('root/title', tool.Tooltip);
            let textWrap = { text: title, width: -10, height: h };
            switch (tool.Shape) {
                case 'EmbeddedImage':
                    m1.attr('image/xlinkHref', tool.Image);
                    textWrap.width = -70;
                    break;

                case 'HeaderedRectangle':
                    m1.attr('headerText/text', tool.HeaderText);
                    m1.attr('header', tool.Header);
                    m1.attr('bodyText', { textWrap: { text: title, width: -10, height: h } });
                    break;
            }
            m1.attr({
                body: tool.Body,
                label: {
                    textWrap: textWrap,
                    fill: '#000',
                    fontSize: 12,
                    fontWeight: 'normal'
                }
            });
            m1.prop({ Type: tool.TypeName, Model: () => model, Schema: tool.Schema });

            if (model && typeof model === 'object' && tool.Ports && tool.Ports.length > 0 && tool.PortGroup != 'none') {
                this.setupGenericWidgetPorts(m1, tool, model);
            }

            m1.addTo(graph);
            return m1;
        },

        createMenu(model, x, y, w, h) {
            const m1 = new this.$joint.shapes.standard.HeaderedRectangle({ // Rectangle({
                position: { x: x, y: y },
                size: { width: w, height: h },
                attrs: {
                },
                ports: {
                    groups: {
                        'out': port_defs_smallfont
                    }
                }
            });

            let title;
            if (model && typeof model === 'object') {
                Vue.set(this.menu_docs, model.Name, model);
                this.menus[model.Name] = m1;

                title = model?.Name;
            }
            else if (model && typeof model === 'string') {
                title = model;
            }

            m1.attr('root/title', 'joint.shapes.standard.HeaderedRectangle');
            m1.attr('headerText/text', 'Menu');
            m1.attr('bodyText/text', title);

            m1.attr({
                header: { fill: '#f8d0d0', rx: 2, ry: 2, strokeWidth: 0.5, stroke: '#000' },
                body: { fill: '#f8c0c0', rx: 5, ry: 5, strokeWidth: 0.5, stroke: '#000' },
                label: {
                    textWrap: { text: title, width: -10, height: 25 },
                    fill: '#000',
                    fontSize: 12,
                    fontWeight: 'normal'
                }
            });
            m1.prop({ Type: 'Menu', Model: () => model, Schema: 'schema/public/Platform.Schema.ACD.v1/MenuTemplate' });

            if (model && model.Options && model.Options.length > 0) {
                this.createMenuPorts(m1, model);
            }
            return m1;
        },
        createMenuPorts(m1, model) {
            const port_array = [];

            for (let i = 0; i < model.Options.length; i++) {
                const opt = model.Options[i];
                port_array.push({
                    group: 'out',
                    attrs: { label: { text: opt.OptionNumber } }
                });
            }

            m1.addPorts(port_array);
        },
        async linkMenu(m1, graph, paper, model, x, y) {
            let x_add = 0;
            if (model && model.Options && model.Options.length > 0)
                for (let i = 0; i < model.Options.length; i++) {
                    const opt = model.Options[i];

                    const src = m1.attributes.ports.items.find(p => p.attrs.label.text == opt.OptionNumber);
                    const src_port = {
                        id: m1.id,
                        magnet: 'portBody',
                        port: src.id,
                    };

                    switch (opt.OptionType) {
                        case 'Enqueue':
                            const q1 = await this.addEnqueue(graph, paper, opt.EnqueueData, x + DEF_X_SPACE * x_add, y + DEF_Y_SPACE, DEF_W, DEF_H);

                            // Link together the menu with the enqueue icon
                            this.addLink(graph, paper, src_port, q1, opt.OptionNumber);
                            x_add++;
                            break;

                        case 'SubMenu':
                            const m = this.menus[opt.SubMenuData.MenuTemplateName];
                            if (m) {
                                // Link together the menu with pre-existing sub-menu
                                this.addLink(graph, paper, src_port, m, opt.OptionNumber);
                            }
                            else {
                                let menu = this.menu_docs[opt.SubMenuData.MenuTemplateName];
                                if (!menu)
                                    menu = await utils.api.get(`Apps/UserDB/SchemaRecordByKey.v2/public/Platform.Schema.ACD.v1/MenuTemplate?rowkey={"Name":"${opt.SubMenuData.MenuTemplateName}"}`);

                                const m2 = await this.addMenu(graph, paper, menu, x + DEF_X_SPACE * x_add, y + DEF_Y_SPACE, DEF_W, DEF_H);

                                // Link together the menu with the sub-menu
                                this.addLink(graph, paper, src_port, m2, opt.OptionNumber);

                                x_add++;
                            }
                            break;

                        default:
                            continue;
                    }
                }

        },
        watchMenu(m1, model, x, y) {
            if (!model.Options)
                Vue.set(model, 'Options', []);

            // Add watch to the Options array which will refresh the list of ports
            return this.$watch(
                function () {
                    const discard1 = model.Name;
                    const discard2 = model.Description;
                    return model.Options;
                },
                function (newv, oldv) {
                    m1.attr('bodyText/textWrap/text', model.Name);
                    m1.attr('root/title', model.Description);

                    let portids = m1.attributes.ports.items;
                    if (!portids) portids = [];

                    // For each menu option, see if we have a port for it. If not, add the port
                    const port_array = [];
                    for (let i = 0; i < model.Options.length; i++) {
                        const opt = model.Options[i];

                        // Skip options that have no digit assigned
                        if (!opt.OptionNumber)
                            continue;

                        const port = portids.find(p => p.attrs.label.text == opt.OptionNumber);
                        if (!port) {
                            port_array.push({
                                group: 'out',
                                attrs: { label: { text: opt.OptionNumber } }
                            });
                        }
                    }
                    if (port_array.length > 0)
                        m1.addPorts(port_array);

                    // Next, for each port, see if we have a menu option for it. If not, remove the port.
                    for (let i = 0; i < portids.length; i++) {
                        var opt = model.Options.find(o => o.OptionNumber == portids[i].attrs.label.text);
                        if (!opt)
                            m1.removePort(portids[i].id);
                    }
                },
                {
                    deep: true
                }
            );
        },
        watchGreeting(m1, model) {
            return this.$watch(
                function () {
                    return model.Name || model.Description;
                },
                function (newv, oldv) {
                    m1.attr('label/textWrap/text', model.Name);
                    m1.attr('root/title', model.Description);
                },
            );
        },
        watchEnqueue(m1, model) {
            if (!model.QueueName)
                Vue.set(model, 'QueueName', '');

            return this.$watch(
                function () {
                    return model;
                },
                function (newv, oldv) {
                    m1.attr('label/textWrap/text', model.QueueName);

                    let portids = m1.attributes.ports?.items;
                    if (!portids) portids = [];

                    const port_array = [];

                    if (model.CheckLoggedIn && !portids.find(p => p.attrs.label.text == 'NoAgents'))
                        port_array.push({
                            group: 'out',
                            attrs: { label: { text: 'NoAgents' } }
                        });
                    else if (!model.CheckLoggedIn && portids.find(p => p.attrs.label.text == 'NoAgents'))
                        m1.removePort(portids.find(p => p.attrs.label.text == 'NoAgents').id);

                    if (model.CheckLoggedIn && model.CheckAgentAvailable && !portids.find(p => p.attrs.label.text == 'N/A'))
                        port_array.push({
                            group: 'out',
                            attrs: { label: { text: 'N/A' } }
                        });
                    else if ((!model.CheckLoggedIn || !model.CheckAgentAvailable) && portids.find(p => p.attrs.label.text == 'N/A'))
                        m1.removePort(portids.find(p => p.attrs.label.text == 'N/A').id);

                    if (port_array.length > 0)
                        m1.addPorts(port_array);
                },
                {
                    deep: true
                }
            );
        },

        updateDynamicPort(m1, p, model, value) {
            let portids = m1.attributes.ports?.items;
            if (!portids) portids = [];

            if (value && !portids.find(p => p.attrs.label.text == p.Label))
                m1.addPorts([{
                    group: 'out',
                    attrs: { label: { text: p.Label } }
                }]);
            else if (!value && portids.find(p => p.attrs.label.text == p.Label))
                m1.removePort(portids.find(p => p.attrs.label.text == p.Label).id);
        },
        updateDynamicListPorts(m1, p, model, array) {
            let portids = m1.attributes.ports?.items;
            if (!portids) portids = [];

            // For each menu option, see if we have a port for it. If not, add the port
            const port_array = [];
            for (let i = 0; i < array.length; i++) {
                const opt = array[i];

                // Skip options that have a false condition
                if (!p.ArrayElementCondition_Func(model, opt))
                    continue;

                // Evaluate the label for this port
                const value = p.ArrayElementLabelExpression_Func(model, opt);
                const port = portids.find(p => p.attrs.label.text == value);
                if (!port) {
                    port_array.push({
                        group: 'out',
                        attrs: { label: { text: value } }
                    });
                }
            }
            if (port_array.length > 0)
                m1.addPorts(port_array);

            // Next, for each port, see if we have an item for it. If not, remove the port.
            for (let i = 0; i < portids.length; i++) {
                var opt = array.find(o => p.ArrayElementCondition_Func(model, o) && p.ArrayElementLabelExpression_Func(model, o) == portids[i].attrs.label.text);
                if (opt === undefined)
                    m1.removePort(portids[i].id);
            }
        },

        watchAnyLabel(m1, model) {
            const tool = this.tooldefinitions[m1.attributes.Type];
            if (!tool) return null;

            this.prepareTool(tool);

            // Watch the label first
            const w1 = this.$watch(
                function () {
                    return model[tool.LabelField];
                },
                function (newv, oldv) {
                    switch (tool.Shape) {
                        case 'HeaderedRectangle':
                            m1.attr('bodyText/textWrap/text', newv);
                            break;
                        default:
                            m1.attr('label/textWrap/text', newv);
                            break;
                    }
                },
            );

            const watches = [ w1 ];
            if (tool.Ports && tool.Ports.length && tool.PortGroup != 'none') {
                // For each port, create a watch that will know when the settings change related to a dynamic port
                for (let i = 0; i < tool.Ports.length; i++) {
                    const p = tool.Ports[i];
                    switch (p.Type) {
                        case 'Dynamic':
                            // Watch the dynamic condition (note that fixed ports do not need to be watched)
                            watches.push(this.$watch(
                                function () {
                                    // Evaluate the condition to be watched
                                    return p.Condition_Func(model);
                                },
                                function (newv, oldv) {
                                    // The condition changed, update the port - add or remove based on the condition
                                    this.updateDynamicPort(m1, p, model, newv);
                                },
                            ));
                            break;

                        case 'DynamicList':
                            // Watch the dynamic array
                            // First, ensure the array has been initialized
                            //if (!p.ArrayField_Func(model)) {
                            //    const f = new Function('Model', `${p.ArrayField} = [];`);
                            //}
                            watches.push(this.$watch(
                                function () {
                                    // Evaluate the condition to be watched
                                    return p.ArrayField_Func(model);
                                },
                                function (newv, oldv) {
                                    // The condition changed, update the port - add or remove based on the condition
                                    this.updateDynamicListPorts(m1, p, model, newv);
                                },
                                {
                                    deep: true
                                }
                            ));
                            break;
                    }
                }
            }
            return watches;
        },

        defaultLink() {
            const link = new this.$joint.shapes.standard.Link();
            link.attr('line/stroke', 'blue');
            link.attr({
                line: {
                    stroke: 'blue',
                    strokeWidth: 0.5,
                },
            });
            link.router('manhattan', {
                startDirection: 'bottom'
            });
            link.connector('rounded');
            link.connector('jumpover', { size: 10 });

            return link;
        },
        validateConnection(cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
            // Prevent linking to magnets (ports)
            //if (!magnetT && cellViewT !== cellViewS) {
            //    console.log(`validateConnection-cellViewT:`);
            //    //this.log(cellViewT);
            //    debugger;
            //}
            return !magnetT; // && (cellViewS.model.attributes.Type != 'Greeting' || (cellViewS.model.attributes.Type == 'Greeting' && cellViewT.model.attributes.Type == 'Menu'));
        },
        addLink(graph, paper, source, target, label) {
            const link = new this.$joint.shapes.standard.Link();
            link.source(source);
            link.target(target);
            link.attr('line/stroke', 'blue');
            link.attr({
                line: {
                    stroke: 'blue',
                    strokeWidth: 0.5,
                },
            });
            if (label)
                link.appendLabel({
                    attrs: {
                        text: {
                            fill: 'blue',
                            text: label
                        },
                        body: {
                            fill: 'white',
                            strokeWidth: 0.5,
                            stroke: 'silver',
                        }
                    },
                    position: {
                        distance: 0.5,
                        args: {
                            keepGradient: true,
                            ensureLegibility: true
                        }
                    }
                });

            link.prop({
                Label: label,
            });
            //link.router('manhattan');
            link.router('manhattan', {
                startDirection: 'bottom'
            });

            link.connector('rounded');
            link.connector('jumpover', { size: 10 });

            link.addTo(graph);
            return link;
        },

        save() {
            if (!this.projectName)
                this.saveAs();
            else
                this.saveGreeting({ name: this.projectName });
        },
        saveAs() {
            this.file_menu_is_open = false;

            const dialog_output = {
                name: "Unnamed",
            };
            this.dialog_width = 400;
            this.dialog_content = (
                <v-card>
                    <v-img height="60px" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg">
                        <v-app-bar flat dark color="rgba(0, 0, 0, 0)">
                            <v-toolbar-title class="text-h6 white--text pl-0" v-else>
                                Save As...
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn elevation={0} icon on-click={() => this.dialog_visible = false}>
                                <v-icon>mdi-close-box</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-img>

                    <v-card-text>
                        <div class="text--primary ma-4">
                            Choose a name to save this greeting flowchart as. Then from a phone number,
                            you may select Greeting Flowchart as the type and pick the name you save as.
                        </div>

                        <v-text-field
                            class="ma-4"
                            label="Greeting Name"
                            autofocus
                            value={dialog_output.name}
                            on-input={(value) => dialog_output.name = value}
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn elevation={0} color="primary" flat on-click={() => { this.dialog_visible = false; this.saveGreeting(dialog_output); } }>OK</v-btn>
                        <v-btn elevation={0} flat on-click={() => this.dialog_visible = false}>Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            );
            this.dialog_visible = true;
        },
        async saveGreeting(dialog_output) {
            const raw = this.graph.toJSON();

            // Reconstitute the models into the output json (it is stored as a function to allow reactivity to be preserved)
            // and store the link destinations in the model.
            const all = this.graph.getElements();
            for (let i = 0; i < raw.cells.length; i++) {
                const cell = raw.cells[i];
                const original = all.find(a => a.id == cell.id);
                if (original && typeof original.attributes.Model === 'function') {
                    // We know this is a regular tool, not a link
                    cell.Model = original.attributes.Model();
                    cell.Model.x = cell.position.x;
                    cell.Model.y = cell.position.y;

                    // Now check for ports and if found, locate the port id in a link to see where it goes
                    if (cell.ports && cell.ports.items && cell.ports.items.length > 0)
                        for (let j = 0; j < cell.ports.items.length; j++) {
                            const port = cell.ports.items[j];
                            const link = raw.cells.find(a => a.type == 'standard.Link' && a.source.port == port.id);
                            if (link) {
                                const target = raw.cells.find(a => a.id == link.target.id);
                                if (target) {
                                    // Found the target of the port - now see what kind of port it is and save the target into the model
                                    const tool = this.tooldefinitions[cell.Type];
                                    if (tool && tool.Ports && tool.Ports.length > 0 && tool.PortGroup != 'none')
                                        this.prepareTool(tool);
                                        for (let k = 0; k < tool.Ports.length; k++) {
                                            const p = tool.Ports[k];
                                            switch (p.Type) {
                                                case 'Fixed':
                                                case 'Dynamic':
                                                    // For Fixed and Dynamic ports, the IdField specifies what field within the Model
                                                    // gets assigned the target id.
                                                    if (p.Label == port.attrs.label.text)
                                                        cell.Model[p.IdField] = target.id;
                                                    break;

                                                case 'DynamicList':
                                                    // For DynamicLists, there is an array and each element in the array may have its own field
                                                    const array = p.ArrayField_Func(cell.Model);
                                                    if (typeof array === 'object' && Array.isArray(array)) {
                                                        const opt = array.find(o => p.ArrayElementLabelExpression_Func(cell.Model, o) == port.attrs.label.text);
                                                        if (opt !== undefined)
                                                            opt[p.ArrayElementIdField] = target.id;
                                                    }
                                                    break;
                                            }
                                        }
                                }
                            }
                        }
                }
            }

            const finalModel = {
                ID: this.projectID,
                Name: dialog_output.name,
                Description: '',
                Graph: raw.cells.filter(a => a.type != 'standard.Link').map(a => ({
                    Id: a.id,
                    Type: a.Type,
                    Model: a.Model,
                }))
            };

            //this.log(raw);
            //this.log(finalModel);

            const res = await utils.api.apiRequest('POST', `Apps/Platform/Schema/FlowWidgets/v1/FlowChart?AutoReplace=true`, finalModel);
            if (res && res.Success) {
                this.projectName = dialog_output.name;
                this.projectID = res.ID;
            }
        },
    },
    props: {
    },
	render(h) {
		//if (!this.model)
		//	return null;
        if (this.loading)
            return null;

        const style = {
            ...this.sizeStyle,
            ...utils.resolveStyleHints(this.styleHints, this),
            display: 'flex',
            flexDirection: 'column',
            height: '1px', // needed by flex-grow
        };

        return (
            <div
                v-show={this.isvisible}
                style={style}
                class={{ 'default-theme': true, 'c-MenuFlowChart': true, [`c-name-${this.name || 'unnamed'}`]: true }}>

                {this.flypaper}

                {this.topMenuBar}

                {this.topArea}

                <splitpanes
                    horizontal={false}
                    push-other-panes={true}
                    first-splitter={false}
                    style="height: 1px; flex-grow: 1;"
                >
                    <pane
                        style="display: flex; flex-direction: column;"
                        key="main"
                        size={75}
                        dbl-click-splitter={true}
                    >
                        {this.mainArea}
                    </pane>

                    <pane
                        key="right"
                        size={25}
                        dbl-click-splitter={true}
                    >
                        <splitpanes
                            horizontal={true}
                            push-other-panes={true}
                            first-splitter={false}
                        >
                            <pane key="right_top" size={50} style="overflow: auto;">
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', height: '100%', border: '1px solid silver' }}>
                                    <span style="width: 100%; border: 1px solid silver; background-color: gray; padding: 2px; color: white; text-align: center;">Tool Palette</span>
                                    {this.rightTopArea}
                                </div>
                            </pane>

                            <pane key="right_bottom" size={50} style="overflow: auto; border: 1px solid silver;">
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', height: '100px' }}>
                                    <span style="width: 100%; border: 1px solid silver; background-color: gray; padding: 2px; color: white; text-align: center;">Properties</span>
                                    {this.rightBottomArea}
                                </div>
                            </pane>
                        </splitpanes>
                    </pane>
                </splitpanes>

                <v-dialog value={this.dialog_visible} max-width={this.dialog_width} scrollable>
                    {this.dialog_content}
                </v-dialog>
            </div>
        );
    }
});