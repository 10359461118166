import Vue from 'vue';
import formInput from './input.jsx';
import formInputMulti from './inputmultiline.jsx';
import formTextEditor from './texteditor.jsx';
import formNumber from './number.jsx';
import formCheckbox from './checkbox.jsx';
import formBoolean from './boolean.jsx';
import formSelect from './select.jsx';
import formAnyOf from './anyof.jsx';
import formArray from './array.jsx';
import nullableType from './nullabletype.jsx';
import schemaProperties from './SchemaProperties.jsx';
import ReferencedDocumentSchema from './referencedDocumentSchema.jsx';
import HsmStatementSchema from './hsmStatementSchema.jsx';
import LookupList from './lookupList.jsx';
import utils from '../../../Shared/utils.jsx';
import methods from '../../../Shared/methods';

function toggleVisible(control, key, child)
{
    const default_value = child <= 0 ? 'true' : 'false';
    const value = control.visible_keys[key] || localStorage.getItem(key);

    if (!value)
    {
        // No setting, save the opposite of the default_value
        const new_value = default_value === 'true' ? 'false' : 'true';
        localStorage.setItem(key, new_value);
        Vue.set(control.visible_keys, key, new_value);
        utils.log('Saved ' + key + ' as ' + new_value);
    }
    else
    {
        const new_value = value === 'true' ? 'false' : 'true';
        if (new_value === default_value)
        {
            // When the value is the default, remove the entry
            localStorage.removeItem(key);
            Vue.delete(control.visible_keys, key);
            utils.log('Deleted ' + key);
        }
        else
        {
            // Otherwise, we must store the value
            localStorage.setItem(key, new_value);
            Vue.set(control.visible_keys, key, new_value);
            utils.log('Saved ' + key + ' as ' + new_value);
        }
    }
}

function getVisibility(control, key, child)
{
    const default_value = child <= 0 ? 'true' : 'false';
    let value = control.visible_keys[key];
    if (!value) {
        value = localStorage.getItem(key);
        if (value)
            Vue.set(control.visible_keys, key, value);
    }

    if (!value)
        return default_value === 'true';
    else
        return value === 'true';
}

function getFormElements(h, control, schema, form_model, element_model, elements, root, child, path)
{
    let cmodel = element_model;
    if (cmodel === undefined || cmodel === null)
        return;

    // Generating an objectId just so Vue will have a unique key associated with each element. Otherwise, changing the cmodel
    // will not necessarily cause it to re-render the contents.
    if (!cmodel.$objectId)
        Vue.set(cmodel, '$objectId', utils.generateUUID());

    let index = 0;
    for (let key in schema.properties) {
        if (key.substr(0, 1) == '$' && key !== '$ref') continue;

        const uniquerowkey = cmodel.$objectId + '_' + key + '_' + index + '_' + child;
        index++;

        const value = element_model[key];
        let element = utils.schema.resolve_Of(schema.properties[key]);
        let Tag = utils.schema.getElementType(element);

        if (!Tag && element.type == 'object') {
            if (!cmodel[key])
                Vue.set(cmodel, key, {});

            if (element.$$condition && typeof element.$$condition === 'function')
                try {
                    const schema_context = utils.schema.getConditionContext(control, { FormModel: control.cmodel, ParentModel: cmodel });

                    if (!element.$$condition(schema_context)) continue;
                }
                catch (e) {
                    utils.warn('Schema condition expression ' + element.condition + ' failed to evaluate: ' + e);
                }

            const visible_key = [...path, key].join('.');
            const vis = getVisibility(control, visible_key, child);
            const icon = vis ? <i class="mdi mdi-minus-box-outline"></i> : <i class="mdi mdi-plus-box"></i>;

            elements.push(
                <tr style={{ backgroundColor: "silver" }} on-click={() => toggleVisible(control, visible_key, child)}>
                    <td colspan="2">
                        <span style={{ marginLeft: (child * 15) + "px" }}><span class="treeview-expand-collapse">{icon}</span> {element.title || key}</span>
                    </td>
                </tr>
            );
            if (vis) {
                if (element.format === 'SchemaProperty')
                    elements.push(
                        <tr>
                            <td colspan="2">
                                <schema-properties
                                    name={element.title || key}
                                    propertygrid={true}
                                    schemakey={key}
                                    schema={element}
                                    root={root}
                                    cmodel={cmodel}
                                    form_model={control.cmodel}
                                    canNavigate={control.canNavigateTo || control.canNavigate}
                                    navigate={(ctrl, prop, value) => control.internalNavigateTo(element, cmodel, key, ctrl, prop, value)}>
                                </schema-properties>
                            </td>
                        </tr>
                    );
                else
                    getFormElements(h, control, element, form_model, cmodel[key], elements, root, child + 1, [...path, key]);
            }
            continue;
        }

        elements.push(
            <Tag
                key={uniquerowkey}
                name={element.title || key}
                propertygrid={true}
                schemakey={key}
                schema={element}
                root={root}
                cmodel={cmodel}
                form_model={form_model}
                child={child}
                path={path}
                readonly={element.readonly || false}
                uniquerowkey={uniquerowkey}
                canNavigate={control.canNavigateTo || control.canNavigate}
                navigateTo={(ctrl, prop, value) => control.internalNavigateTo(element, cmodel, key, ctrl, prop, value)}>
            </Tag>
        );
    }
}

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

Vue.component('property-grid', {
    data: () => ({
        current_model: null,
        breadcrumb: [],
        visible_keys: {},
        //pinned_tabs: [{ "Title": "Properties" }],
        //selected_tab_index: 0
    }),
    created() {
        this.current_model = this.cmodel;
        this.$parent.$on('reset-breadcrumb', this.resetBreadcrumb);
    },
    props: {
        name: '',
        schemakey: null,
        root: null,
        schema: null,
        form: null,
        cmodel: null,
        child: 0,
        readonly: false,
        extra: null,
        navigateToModel: null,
        navigateTo: null,
        canNavigate: false,
    },
    computed: {
        ...utils.forms.computed,
        canNavigateTo: function () {
            return this.navigateToModel ? true : false;
        }
    },
    methods: {
        textChanged(prop, cmodel, value) {
            try {
                const obj = JSON.parse(value);
                Vue.set(cmodel, prop, obj);
            }
            catch (e)
            {
                utils.warn('Property grid failed to convert value to object: ' + e);
            }
        },
        internalNavigateTo(schema, cmodel, key, ctrl, prop, value) {
            if (this.navigateTo)
                this.navigateTo(ctrl, prop, value);
            else if (this.navigateToModel) {
                this.breadcrumb.push({ cmodel: this.cmodel, prevmodel: cmodel });
                if ((value === null || typeof value === 'undefined') && prop in cmodel)
                    this.navigateToModel(cmodel[prop]);
                else
                    this.navigateToModel(value);
            }
        },
        breadcrumbBack(index) {
            if (this.navigateToModel) {
                const newmodel = this.breadcrumb[index].cmodel;
                if (index === 0)
                    this.breadcrumb = [];
                else
                    this.breadcrumb = this.breadcrumb.slice(0, index);

                this.navigateToModel(newmodel);
            }
        },
        resetBreadcrumb() {
            this.breadcrumb = [];
        }
    },
    render(h) {
        let schema = this.schema;
        let elements = [];
        if (!schema)
            return null;

        schema = utils.schema.resolve_Of(schema);

        if (schema.type !== 'object')
            return <div>Schema must be an object</div>;

        //console.log(`--- property-grid render:`);
        //console.log(`--- cmodel: ${JSON.stringify(this.cmodel, getCircularReplacer(), 3)}`);
        //console.log(`--- schema: ${JSON.stringify(this.schema, getCircularReplacer(), 3)}`);

        //TODO: Handle user selected type - $typeSchema is UserSelectedType (or similar), members include $ref that has a format (not sure how we should recognize it correctly)

        let cmodel = this.cmodel;
        if (this.schemakey !== null && !(typeof this.schemakey === 'undefined'))
            cmodel = this.cmodel[this.schemakey];

        if (this.breadcrumb && this.breadcrumb.length > 0)
        {
            const crumbs = [];
            for (let i = 0; i < this.breadcrumb.length; i++)
            {
                const bc = this.breadcrumb[i];
                let name;
                if (bc.prevmodel && bc.prevmodel.title)
                    name = bc.prevmodel.title;
                else if (bc.prevmodel && bc.prevmodel.Name)
                    name = bc.prevmodel.Name;
                else
                    name = bc.cmodel.$typeSchema;

                if (name) {
                    // Example: /schema/public/Platform.Schema.DynamicControls.v1/DynamicControl_HSM_State
                    name = name.split('/');
                    name = name[name.length - 1];
                    if (name.includes('_')) {
                        name = name.split('_');
                        name = name[name.length - 1];
                    }
                }
                else
                    name = 'Back';

                const crumb_style = {
                    margin: "5px",
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "gray",
                    //textDecoration: "underline",
                    fontSize: "small",
                    fontFamily: "Arial",
                    margin: "3px",
                    padding: "2px",
                    borderRadius: "2px",
                };

                crumbs.push(
                    <span style={crumb_style} title="Back to Parent" on-click={() => this.breadcrumbBack(i)}><i class="mdi mdi-menu-left"></i> {name}</span>
                );
            }
            elements.push(
                <tr>
                    <td colSpan="2">
                        {crumbs}
                    </td>
                </tr>
            );
        }

        getFormElements(h, this, schema, this.FormModel, cmodel, elements, this.root, this.child, [ schema.Id ]);

        //const tabs = [];
        //const tab_contents = [];
        //for (let i = 0; i < this.pinned_tabs.length; i++)
        //{
        //    const pin = this.pinned_tabs[i];
        //    let color;
        //    if (i == this.selected_tab_index)
        //        color = { color: "black", backgroundColor: "white", borderStyle: "solid solid none solid" };
        //    else
        //        color = { color: "silver", backgroundColor: "gray", borderStyle: "none" };

        //    tabs.push(
        //        <span style={{ cursor: "pointer", padding: "3px", borderWidth: "1px", borderColor: "black", borderRadius: "5px 5px 0 0", float: "left", marginRight: "2px", ...color }} onClick={(e) => this.selected_tab_index = i}>
        //            {pin.Title}
        //        </span>
        //    );
        //}

        //const mainstyle = { width: "100%" };
        //if (this.selected_tab_index !== 0)
        //    mainstyle.display = "none";

        return (
            <table style={{ width: "100%", paddingBottom: "30px", height: "fit-content", fontSize: "14px", borderCollapse: "separate"}}>
                {elements}
            </table>
        );
    }
});