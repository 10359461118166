<template class="c-ToolPanel">
    <div class="c-ToolPanel_1 mx-auto w-100 pa-0" style="width: 100%; display: flex; flex-direction: column; min-width: 750px; max-width: 750px; background-color: var(--v-extraLightGrey-base);" v-show="isvisible">
        <div style="overflow: auto; width: 100%; display: flex; flex-direction: column; flex-basis: 0; flex-grow: 1;">
            <v-expansion-panels accordion hover v-model="selectedPanel">
                
                <v-expansion-panel>
                    <v-expansion-panel-header class="text-h6">
                        Details
                        <template v-slot:actions>
                            <v-icon color="primaryText" size="30px">
                                mdi-menu-down
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-card-subtitle class="mt-0 mb-1 consistentText">
                            Provide basic report information and setup
                        </v-card-subtitle>

                        <v-row dense class="ml-1">
                            <v-col cols="12">
                                <v-text-field dense outlined hide-details label="Report Name" v-model="report_name" class="standard-input-field" background-color="white"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense class="ml-1 mt-2">
                            <v-col cols="12">
                                <v-text-field dense outlined hide-details label="Description" v-model="report_description" class="standard-input-field" background-color="white"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense class="ml-1 mt-2">
                                    <v-col cols="11">
                                        <v-autocomplete 
                                        dense 
                                        outlined 
                                        hide-details 
                                        label="Category"
                                        class="standard-input-field"
                                        :menu-props="{ offsetY: true}"
                                        :items="reportCategories"
                                        v-model="report_category"
                                        item-text="Name" 
                                        item-value="ID"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="1" class="d-flex align-center justify-center">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" @click="refreshCategories">
                                            mdi-cached
                                            </v-icon>
                                        </template>
                                        <span>Refresh category list</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" @click="categoryActions" class="left-pad-4">
                                            mdi-cog-outline
                                            </v-icon>
                                        </template>
                                        <span>Manage categories</span>
                                        </v-tooltip>
                                    </v-col>
                        </v-row>


                        <v-expansion-panels class="mt-3" v-model="advancedVisible">
                            <v-expansion-panel>
                                <v-expansion-panel-header class="pl-3 consistentText" >
                                    Advanced settings
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <!--<v-card-subtitle>
                                        Server-side pagination algorithm when not sorted by a metric. Metrics require bucket pagination, which is less efficient.
                                        After-Key is the most efficient, but prevents refreshing after pagination without resetting your view to the top. Bucket
                                        allows your position in the pagination to be retained, but at a higher cost.
                                    </v-card-subtitle>
                                    <v-radio-group v-model="serverPaginationMode">
                                        <v-radio :value="1" label="After-Key Pagination"></v-radio>
                                        <v-radio :value="2" label="Bucket Pagination"></v-radio>
                                    </v-radio-group>-->

                                    <v-card-subtitle class="consistentText">Server-side pagination settings.</v-card-subtitle>

                                    <v-row no-gutters class="mt-2" align="center">
                                        <v-col cols="5">
                                            <v-text-field dense outlined hide-details
                                                          label="Normal Page Size"
                                                          type="number"
                                                          v-model="pageSize" :rules="pageSizeRules"
                                                          class="_standard-input-field"
                                                          background-color="white">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="1" class="pl-2">
                                            <v-tooltip bottom open-delay="50">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                                                </template>
                                                Controls how many rows are shown per page of the report. For example, if a report has 1000 rows and Normal Page Size is set to 100, the report will display 10 pages with 100 rows on each page.
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-text-field dense outlined hide-details
                                                          label="Presort Page Size"
                                                          type="number"
                                                          v-model="presortSize" :rules="presortSizeRules"
                                                          class="_standard-input-field"
                                                          background-color="white">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="1" class="pl-2">
                                            <v-tooltip bottom open-delay="50">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                                                </template>
                                                Adjusts the number of rows the server analyzes when sorting by a metric, impacting sort accuracy and performance.
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>

                                    <v-card-subtitle class="consistentText" >Pivot column settings.</v-card-subtitle>

                                    <v-row no-gutters class="mt-2" align="center">
                                        <v-col>
                                            <v-text-field label="Maximum Columns"
                                                          type="number" outlined dense hide-details
                                                          _class="ml-2 mb-2 _standard-input-field"
                                                          _style="max-width: 100px;"
                                                          v-model.number="settings.max_pivot_columns"
                                                          hint="Deterimnes the maximum number of columns derived from unique values from the pivot column"
                                                          background-color="white"
                                                          @change="refreshReport" />
                                        </v-col>
                                        <v-col cols="1" class="pl-2">
                                            <v-tooltip bottom open-delay="50">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                                                </template>
                                                Limit the number of columns generated when pivoting on a grouping column. This prevents excessive columns from cluttering the report.
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-expansion-panel-content>

                </v-expansion-panel>
                
                <v-expansion-panel>
                    <v-expansion-panel-header class="text-h6">
                        Metrics
                        <template v-slot:actions>
                            <v-icon color="primaryText" size="30px">
                                mdi-menu-down
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content >

                        <v-card-subtitle class="mt-0 consistentText">
                            Select the metrics to include in the report. Use the search box or the filter icon to narrow the choices.
                        </v-card-subtitle>

                        <OverflowPillGroup
                            class="py-0 px-2"
                            :removePill="removeMetric"
                            :pills="metrics"
                            typeDescriptor="metrics"
                            >
                        </OverflowPillGroup>


                        <v-card-actions>
                            <!--<input type="text" placeholder="Search" size="40" v-model="search_metrics" style="border: 1px solid silver; border-radius: 3px; padding-left: 3px; padding-bottom: 1px;" />-->
                            <v-text-field outlined dense hide-details clearable label="Search" v-model="search_metrics" class="_standard-input-field" background-color="white">
                                <template v-slot:prepend-inner>
                                    <v-icon>mdi-magnify</v-icon>
                                </template>
                                <template v-slot:append-outer>

                                    <v-menu offset-y :close-on-content-click="false" :close-on-click="false" v-model="metric_filter_menuopen">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-badge color="primary" dot offset-y="25" offset-x="10" :value="anyMetricfilters">
                                                <v-btn icon small v-bind="attrs" v-on="on" class="ma-0">
                                                    <v-icon>mdi-filter</v-icon>
                                                </v-btn>
                                            </v-badge>
                                        </template>
                                        <v-card @mouseleave="metric_filter_menuopen = false" class="pa-1" style="_max-height: 500px; min-width: 400px;">
                                            <MetricTypeFilter :debugMode="debugMode"
                                                              :datatypes="metricDataTypes"
                                                              :initial_datatypes_selection="metricDataTypes_selection"
                                                              @datatypes_changed="(values) => metricDataTypes_selection = values"
                                                              
                                                              :categories="metricCategories"
                                                              :initial_category_selection="metricCategory_selection"
                                                              @categories_changed="(values) => metricCategory_selection = values"
                                                              
                                                              :sources="metricSources"
                                                              :initial_sources_selection="metricSources_selection"
                                                              @sources_changed="(values) => metricSources_selection = values"
                                                              
                                                              @anyfilters="(value) => anyMetricfilters = value">
                                                
                                            </MetricTypeFilter>
                                        </v-card>
                                    </v-menu>

                                </template>
                            </v-text-field>
                        </v-card-actions>

                        <div v-if="search_metrics">
                            <v-card-subtitle style="border-bottom: 1px solid silver; color: #9d9d9d;" class="mb-0 pb-0">SEARCH RESULTS</v-card-subtitle>

                            <MetricItemList :items="metricsearchresults"
                                            :columnLookup="columnLookup"
                                            :isMetricInUse="isMetricInUse"
                                            :getMetricItemIcon="getMetricItemIcon"
                                            :showHelp="showHelp"
                                            :isDisabledMetric="isDisabledMetric"
                                            :isDisabledSource="isMetricDisabledSource"
                                            :addMetric="addMetric"
                                            :removeMetric="removeMetric"
                                            :getMetricHelp="getMetricHelp"
                                            :metric_types="metric_types"></MetricItemList>

                        </div>

                        <div v-else v-for="(group,i) in metricGroups" :key="i">
                            <v-card-subtitle v-if="isMetricGroupVisible(group)"
                                             style="border-bottom: 1px solid lightGrey;" class="mb-0 pb-0 consistentText">{{ group.text.toUpperCase() }}</v-card-subtitle>

                            <div v-if="isMetricGroupVisible(group)">
                                <MetricItemList :items="getMetricColumns(group)"
                                                :columnLookup="columnLookup"
                                                :isMetricInUse="isMetricInUse"
                                                :getMetricItemIcon="getMetricItemIcon"
                                                :showHelp="showHelp"
                                                :isDisabledMetric="isDisabledMetric"
                                                :isDisabledSource="isMetricDisabledSource"
                                                :addMetric="addMetric"
                                                :removeMetric="removeMetric"
                                                :getMetricHelp="getMetricHelp"
                                                :metric_types="metric_types"></MetricItemList>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="text-h6">
                        Groupings
                        <template v-slot:actions>
                            <v-icon color="primaryText" size="30px">
                                mdi-menu-down
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-card-subtitle class="mt-0 consistentText">
                            Choose the fields you want to group report data by (maximum permitted is four). Remember that only one Date/Time field can be chosen.
                        </v-card-subtitle>

                        <v-card-subtitle class="consistentText mt-0 pt-0">
                            Drag and drop the fields already selected to change their order.
                        </v-card-subtitle>

                        <v-card-title class="subtitle-1" style="overflow: auto;">
                            <div class="ma-0 pa-0 consistentText">
                                Grouping order:
                            </div>
                            <Container @drop="onDrop" orientation="horizontal" style="margin-left: 20px;">
                                <Draggable v-for="(c,i) in groupby" :key="c" :index="i">
                                    <v-chip class="draggable-item" color="primary" outlined>
                                        {{ groupColumnLookup[c].Title }}
                                    </v-chip>
                                </Draggable>
                            </Container>
                        </v-card-title>

                        <v-card-actions>
                            <v-text-field outlined dense hide-details clearable label="Search" v-model="search_groups" class="_standard-input-field" background-color="white">
                                <template v-slot:prepend-inner>
                                    <v-icon>mdi-magnify</v-icon>
                                </template>
                                <template v-slot:append-outer>

                                    <v-menu offset-y :close-on-content-click="false" :close-on-click="false" v-model="group_filter_menuopen">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-badge color="primary" dot offset-y="25" offset-x="10" :value="anyGroupfilters">
                                                <v-btn icon small v-bind="attrs" v-on="on" class="ma-0">
                                                    <v-icon>mdi-filter</v-icon>
                                                </v-btn>
                                            </v-badge>
                                        </template>
                                        <v-card @mouseleave="group_filter_menuopen = false" class="pa-1" style="_max-height: 500px; min-width: 400px;">
                                            <MetricTypeFilter :debugMode="debugMode"
                                                              :datatypes="groupDataTypes"
                                                              :initial_datatypes_selection="groupDataTypes_selection"
                                                              @datatypes_changed="(values) => groupDataTypes_selection = values"

                                                              :categories="groupCategories"
                                                              :initial_category_selection="groupCategory_selection"
                                                              @categories_changed="(values) => groupCategory_selection = values"

                                                              :sources="groupSources"
                                                              :initial_sources_selection="groupSources_selection"
                                                              @sources_changed="(values) => groupSources_selection = values"

                                                              @anyfilters="(value) => anyGroupfilters = value">

                                            </MetricTypeFilter>
                                        </v-card>
                                    </v-menu>

                                </template>
                            </v-text-field>
                        </v-card-actions>

                        <v-card-actions v-if="getPivotOn">
                            <v-checkbox class="mr-4 mb-0" _value="getPivotOn.ColId" dense hide-details v-model="pivoton">
                                <template v-slot:label>
                                    <div class="consistentText">
                                        {{'Pivot on ' + getPivotOn.Title}}
                                    </div>
                                </template>
                            </v-checkbox>

                            <v-tooltip bottom open-delay="50">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" class="mt-2" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                                </template>
                                Pivoting is available for the last column in your group list. You can rearrange grouping columns<br />
                                by dragging and dropping them in the pills list above. Pivoting generates a distinct column for<br />
                                every unique value within each row.
                            </v-tooltip>
                        </v-card-actions>

                        <div v-if="!search_groups">
                            <v-card-subtitle style="border-bottom: 2px solid lightgrey;" class="mb-0 pb-0 consistentText">DATE / TIME</v-card-subtitle>

                            <div>
                                <GroupbyItemList :items="[defaultTimeColumn]"
                                                 :groupbysettings="groupbysettings"
                                                 :isMetricInUse="(g) => groupby.includes(g.ColId)"
                                                 :showHelp="showHelp"
                                                 :isDisabledSource="isGroupDisabledSource"
                                                 :toggleGroupby="toggleGroupby"
                                                 :getMetricHelp="getMetricHelp"
                                                 :metric_types="metric_types"
                                                 :interval_choice="interval_choice"
                                                 @settingschanged="setgroupbysettings"
                                                 @intervalchanged="(i) => interval_choice = i"></GroupbyItemList>
                            </div>
                        </div>

                        <div v-if="search_groups">
                            <v-card-subtitle style="border-bottom: 2px solid lightgrey;" class="mb-0 pb-0 consistentText">SEARCH RESULTS</v-card-subtitle>

                            <div>
                                <GroupbyItemList :items="groupsearchresults"
                                                 :groupbysettings="groupbysettings"
                                                 :isMetricInUse="(g) => groupby.includes(g.ColId)"
                                                 :showHelp="showHelp"
                                                 :isDisabledSource="isGroupDisabledSource"
                                                 :toggleGroupby="toggleGroupby"
                                                 :getMetricHelp="getMetricHelp"
                                                 :refreshReport="refreshReport"
                                                 :metric_types="metric_types"
                                                 @settingschanged="setgroupbysettings"></GroupbyItemList>
                            </div>
                        </div>

                        <div v-else v-for="(group,i) in groupingGroups" :key="i">
                            <v-card-subtitle v-if="isGroupVisible(group)"
                                             style="border-bottom: 2px solid lightgrey;;" class="mb-0 pb-0 consistentText">{{ group.text.toUpperCase() }}</v-card-subtitle>

                            <div v-if="isGroupVisible(group)">
                                <GroupbyItemList :items="getGroupColumns(group)"
                                                 :groupbysettings="groupbysettings"
                                                 :isMetricInUse="(g) => groupby.includes(g.ColId)"
                                                 :showHelp="showHelp"
                                                 :isDisabledSource="isGroupDisabledSource"
                                                 :toggleGroupby="toggleGroupby"
                                                 :getMetricHelp="getMetricHelp"
                                                 :refreshReport="refreshReport"
                                                 :metric_types="metric_types"
                                                 @settingschanged="setgroupbysettings"></GroupbyItemList>
                            </div>
                        </div>

                        <!--<v-card-actions v-if="getPivotOn">
        <v-checkbox class="mr-5 mb-0" :label="'Pivot on ' + getPivotOn.Title" :value="getPivotOn.ColId" dense v-model="pivoton"></v-checkbox>
        <span v-if="pivoton" class="mb-3">="Max Columns:pa</span>
        <input v-if="pivoton" type="number" size="2" v-model="max_pivot_columns" class="ml-2 mb-2" style="max-width: 40px; border: 1px solid silver; border-radius: 3px; padding-left: 3px; padding-bottom: 1px;" />
    </v-card-actions>-->
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header hide-actions class="text-h6">
                        <template v-slot:default="{ open }">
                            Filters
                            <v-card class="ma-0 pa-0 d-flex" flat color="transparent">
                                <v-icon class="mr-auto expandIconSpoof" :class="{'rotate': open}" color="primaryText" size="30px" >
                                    mdi-menu-down
                                </v-icon>
                                <v-btn plain :elevation="0" color="primary" title="Clear All Filters" class="mr-3 v-btn--active"
                                       style="max-height: 30px;"
                                       v-if="column_filters && Object.keys(column_filters).length > 0" :disabled="!open"
                                       @click.stop="clearAllFilters">
                                    Clear All
                                </v-btn>
                            </v-card>
                        </template>
                    </v-expansion-panel-header>


                    <v-expansion-panel-content>
                        <ToolReportFilters :isvisible="true"
                                           :ReadOnly="false"
                                           :settings="settings"
                                           :column_list="column_list"
                                           :column_filters="column_filters"
                                           :report_settings="report_settings"
                                           :EventBus="EventBus"
                                           :debugMode="debugMode"
                                           @filterschanged="filterschanged">
                        </ToolReportFilters>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </div>

        <v-dialog v-model="help.visible" max-width="590">
            <v-card>
                <v-card-title class="text-h5">
                    {{ help.item ? help.item.Title : '' }}
                </v-card-title>

                <v-card-text v-html="help.item.Description">

                <!--{{ help.item.Description }}-->
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary darken-1"
                           text
                           @click="help.visible = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="filter.visible" max-width="590">
            <v-card>
                <v-card-title class="text-h5">
                    Filter for {{ filter.item.calculation }}({{ filter.item.Title }})
                </v-card-title>

                <v-card-text>
                    This applies a filter to the metric calculation to control which records are included.
                    You may use the same column multiple times with different filters if you like.

                    <v-text-field dense outlined hide-details
                                  label="Name"
                                  class="mt-5 mb-5"
                                  v-model="filter.item.name"
                                  background-color="white">
                    </v-text-field>

                    Imagine the filter settings right here.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1"
                           text
                           @click="filter.visible = false">
                        Save
                    </v-btn>

                    <v-btn color="green darken-1"
                           text
                           @click="filter.visible = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    /* eslint-disable */

    import Vue from "vue";
    import helps from '../../../careHelpfulFunctions.jsx';
    import EventBus from '../../../event-bus.js';

    import { Container, Draggable } from 'vue-smooth-dnd';
    import MenuColumnGroups from './menuColumnGroups.vue';
    import PopupMenuSelection from './menuIntervalSelection.vue';
    import CardHistogram from './cardHistogram.vue';
    import MetricItem from './metricItem.vue';
    import MetricItemList from './metricItemList.vue';
    import GroupbyItemList from './groupbyItemList.vue';
    import ToolReportFilters from './agGridToolReportFilters.vue';
    import MetricTypeFilter from './metricTypeFilterPopup.vue';
    import OverflowPillGroup from './overflowPillGroup.vue';

    import cDateRangePicker from '../DateRangePicker.vue';

    import { appSettings } from '@/Shared/appSettings';

    import utils from '../../../../Shared/utils.jsx';

    import common from './common.js';

    const groupCols = ['dateColumn', 'textSetColumn', 'channelTypeColumn', 'directionColumn', 'userIDColumn'];
    const metricCols = ['durationColumn', 'countColumn'];

    export default {
        components: {
            Container,
            Draggable,
            MenuColumnGroups,
            PopupMenuSelection,
            CardHistogram,
            MetricItem,
            MetricItemList,
            GroupbyItemList,
            ToolReportFilters,
            MetricTypeFilter,
            cDateRangePicker,
            OverflowPillGroup,
        },
        props: {
            EventBus: null,
            column_list: {
                type: Array,
                required: false
            },
            column_filters: null,
            settings: null,
            report_settings: null,
            initial_userStartEnd: null,
            storageKey: null,
            datetime_interval: null,
            notifychange: null,
            openadvancedsettings: null,
            isvisible: null,
            initial_report_name: null,
            initial_report_description: null,
            initial_report_category: null,
            control_category_actions : null,
            debugMode: null,
        },
        data: function () {
            return {
                greeting: 'Hello',
                changing: 0,
                useStartEnd: true,
                groupby: ['datetime'],
                groupbysettings: {},
                pivoton: false,
                search_metrics: '',
                search_groups: '',
                interval_choice: '',
                interval_selection: 2,
                defaultTimeColumn: {
                    ColId: 'datetime',
                    Title: 'Date / Time',
                    Tooltip: 'Date and time based on the start of the interaction or state',
                    Description: 'This is the general date and time column. When used for grouping, you must choose the interval. The actual field used for the grouping may be determined based on the metric(s) selected. The default is the starttime field.',
                    Category: 'Dates & Times',
                    SourceType: 'dateColumn'
                },
                active_interval: '1h',
                defaultTime: 'today',
                advancedVisible: undefined,

                formatHeader: common.formatHeader,

                selectedPanel: 0,
                max_pivot_columns: 25,

                metricDataTypes: [
                    { text: 'Counts', filter: 'type', types: ['countColumn', 'floatColumn'] },
                    { text: 'Dates / Times', filter: 'type', types: ['dateColumn', 'dateOnlyColumn', 'timeOnlyColumn'] },
                    { text: 'Durations', filter: 'type', types: ['durationColumn'] },
                    { text: 'Percents', filter: 'type', types: ['percentColumn'] },
                    { text: 'Text Values', filter: 'type', types: ['textSetColumn', 'searchableTextColumn', 'channelTypeColumn', 'directionColumn', 'userIDColumn', 'customerIDColumn', 'phoneNumColumn'] },
                    { text: 'True / False', filter: 'type', types: ['booleanColumn'] },
                ],
                metricCategories: [],
                metricSources: [],

                anyMetricfilters: false,

                metricDataTypes_selection: [],
                metricCategory_selection: [],
                metricSources_selection: [],

                groupDataTypes: [
                    { text: 'Durations', filter: 'type', types: ['durationColumn'] }, //0
                    { text: 'Text Values', filter: 'type', types: ['textSetColumn', 'searchableTextColumn', 'channelTypeColumn', 'directionColumn', 'userIDColumn', 'customerIDColumn', 'phoneNumColumn'] }, //1
                ],
                groupCategories: [],
                groupSources: [],

                anyGroupfilters: false,

                groupDataTypes_selection: [],
                groupCategory_selection: [],
                groupSources_selection: [],

                metricgroup_expanded: [0],
                columnmetrics_selection: [],

                metric_types: {
                    avg: { text: 'Average', icon: 'mdi-calculator' },
                    max: { text: 'Longest', icon: 'mdi-code-greater-than' },
                    min: { text: 'Shortest', icon: 'mdi-code-less-than' },
                    sum: { text: 'Sum', icon: 'mdi-sigma' },
                    cardinality: { text: 'Cardinality', icon: 'mdi-counter' },
                    value_count: { text: 'Hit Count', icon: 'mdi-counter' },
                    bucket: { text: 'Percent', icon: 'mdi-percent-circle-outine' },
                },
                metrics: [],
                metricMenu: [],
                metric_filter_menuopen: false,
                group_filter_menuopen: false,

                group_col_settings_open: false,
                help: {
                    visible: false,
                    item: {}
                },
                filter: {
                    visible: false,
                    item: {}
                },

                pageSizeRules: [value => (value >= 10 && value <= 10000) || 'Between 10 - 10,000 only'],
                presortSizeRules: [value => (value >= 10 && value <= 10000) || 'Between 10 - 10,000 only'],

                report_name: '',
                report_description: '',
                report_category: '',
                reportCategories: []
            }
        },
        watch: {
            metricDataTypes_selection: function (newv) {
                this.saveMetricFilters();
            },
            metricCategory_selection: function (newv) {
                this.saveMetricFilters();
            },
            metricSources_selection: function (newv) {
                this.saveMetricFilters();
            },
            groupDataTypes_selection: function (newv) {
                this.saveGroupFilters();
            },
            groupCategory_selection: function (newv) {
                this.saveGroupFilters();
            },
            groupSources_selection: function (newv) {
                this.saveGroupFilters();
            },

            groupby: function (newv) {
                this.notifyGroupOrder();
                this.refreshReport();
            },
            metrics: function (newv) {
                this.refreshReport();
            },
            pivoton: function (newv) {
                this.refreshReport();
            },
            getPivotOn: function (newv, oldv) {
                if (newv !== oldv && !newv && this.pivoton)
                    this.pivoton = false;
            },
            interval_choice: function (newv) {
                appSettings.LocalStorage(this.storageKey + '-interval', newv);
                this.refreshReport();
            },
            columngroups_selection: function (newv) {
                appSettings.LocalStorage(this.storageKey + '-groupcategories', JSON.stringify(newv));
            },
            columnmetrics_selection: function (newv) {
                appSettings.LocalStorage(this.storageKey + '-metriccategories', JSON.stringify(newv));
            },
            useStartEnd: function (newv) {
                this.$emit('changeusestartend', newv);
            },

            report_name: function (newv) {
                this.$emit('namechanged', newv);
            },
            report_description: function (newv) {
                this.$emit('descriptionchanged', newv);
            },
            report_category: function (newv) {
                this.$emit('categorychanged', newv);
            },
        },
        created() {
            //if (this.datetime_interval)
            //    this.interval_choice = this.datetime_interval;

            this.defaultTime = JSON.stringify({ startDate: this.settings.startDate, endDate: this.settings.endDate });

            const oldinterval = appSettings.LocalStorage(this.storageKey + '-interval');
            if (oldinterval)
                this.interval_choice = oldinterval;
            else
                this.interval_choice = '1d';

            //------------------------ New --------------------//

            // Populate a distinct list of categories for metric filters
            this.metricCategories = [...new Set(this.column_list.filter(a => a.ColumnUse.Metric).map(a => a.Category))]
                .toSorted((a, b) => common.byField(a, b))
                .map(a => ({ text: a, filter: 'Category', category: a }));

            // Populate a distinct list of source names (contacts, users, etc)
            this.metricSources = [...new Set(this.column_list.filter(a => a.ColumnUse.Metric).flatMap(a => a.Sources))]
                .toSorted((a, b) => common.byField(common.sourceTypes[a], common.sourceTypes[b]))
                .map(a => ({ text: a, filter: 'Source', source: a }));

            // Populate a distinct list of categories for groupby filters
            this.groupCategories = [...new Set(this.column_list.filter(a => a.ColumnUse.Group).map(a => a.Category))]
                .toSorted((a, b) => common.byField(a, b))
                .map(a => ({ text: a, filter: 'Category', category: a }));

            // Populate a distinct list of source names (contacts, users, etc)
            this.groupSources = [...new Set(this.column_list.filter(a => a.ColumnUse.Group).flatMap(a => a.Sources))]
                .toSorted((a, b) => common.byField(common.sourceTypes[a], common.sourceTypes[b]))
                .map(a => ({ text: a, filter: 'Source', source: a }));

            this.loadMetricFilters();
            this.loadGroupFilters();
            this.refreshCategories();
            //-------------------------------------------------//

            if (this.report_settings && this.report_settings.groupby) {
                console.log('created report fields, report_settings:');
                console.log(JSON.stringify(this.report_settings,null,3));

                this.groupby = this.report_settings.groupby.map(a => a.colId);

                if (this.report_settings.pivoton)
                    this.groupby.push(this.report_settings.pivoton);

                this.metrics = this.report_settings.metrics.map(a => ({
                    id: `${a.function}(${a.colId})`,
                    calculation: a.function,
                    ...this.column_list.find(c => c.ColId == a.colId),
                }));
                this.interval_choice = this.report_settings.datetime_interval;
                this.pivoton = !!this.report_settings.pivoton;
            }
            else
                console.log('created report fields, report_settings empty');

            this.report_name = this.initial_report_name;
            this.report_description = this.initial_report_description;
            this.report_category = this.initial_report_category;
            this.useStartEnd = this.initial_userStartEnd;
        },
        mounted() {
        },
        computed: {
            groupColumnLookup: function() {
                return helps.toLookup(this.groupingColumns, 'ColId');
            },
            columnLookup: function() {
                return helps.toLookup(this.column_list, 'ColId');
            },
            groupingGroups: function() {
                return [...new Set(this.column_list.filter(a => a.ColumnUse.Group && a.SourceType != 'dateColumn').map(a => a.Category))]
                    .toSorted((a, b) => common.byField(a, b))
                    .map(a => ({ text: a, filter: 'Category', category: a }));
            },
            groupingColumns: function() {
                const cols = this.column_list.filter(a => a.ColumnUse.Group);
                return [{
                    ColId: 'datetime',
                    Title: 'Date / Time',
                    SourceType: 'dateColumn',
                    Sources: ['contacts', 'users', 'ivr'],
                    Category: 'Dates / Times',
                }, ...cols ];
            },
            metricGroups: function () {
                return [...new Set(this.column_list.filter(a => a.ColumnUse.Metric).map(a => a.Category))]
                    .toSorted((a, b) => common.byField(a, b))
                    .map(a => ({ text: a, filter: 'Category', category: a }));
            },
            
            groupsources_selected: function () {
                if (this.groupby.length < 1) return [];

                // Gets a distinct list of sources (users, contacts, etc) that are selected in the groupby fields
                // Need to find any columns that have only one or the other source. Ex:
                // starttime: [users, contacts]
                // team:      [users, contacts]
                // statename: [users]
                // This means, only users since statename doesn't have contacts.
                // We have to prevent selecting a groupby with one that is only users and one that is only contacts

                // Get all the Sources and sort the list by the length of the Sources list -- the lowest length is the one with the fewest sources.
                // That is the one we will return.

                const all = this.groupby.map(a => this.column_list.find(b => b.ColId == (a == 'datetime' ? 'starttime' : a))).map(a => a.Sources)
                    .toSorted((a, b) => a.length < b.length ? -1 : a.length > b.length ? 1 : 0);

                return all[0];
            },
            metricsources_selected: function () {
                if (this.metrics.length < 1) return [];

                // Return a distinct list of all sources from the selected metrics
                return [... new Set(this.metrics.flatMap(a => a.Sources))];
            },

            groupsearchresults: function () {
                const cols = this.column_list.filter(a => a.ColumnUse.Group && !a.CompoundFilter && a.SourceType != 'dateColumn');
                return cols.filter(a =>
                    a.ColId.toLowerCase().includes(this.search_groups.toLowerCase()) ||
                    a.Title.toLowerCase().includes(this.search_groups.toLowerCase()) ||
                    a.Tooltip?.toLowerCase().includes(this.search_groups.toLowerCase()) ||
                    a.Description?.toLowerCase().includes(this.search_groups.toLowerCase()) ||
                    a.SourceType?.toLowerCase().includes(this.search_groups.toLowerCase())
                ).toSorted((a, b) => this.byField(a, b, 'Title'));
            },
            metricsearchresults: function(){
                const cols = this.column_list.filter(a => a.ColumnUse.Metric && !a.CompoundFilter);
                return cols.filter(a =>
                    a.ColId.toLowerCase().includes(this.search_metrics.toLowerCase()) ||
                    a.Title.toLowerCase().includes(this.search_metrics.toLowerCase()) ||
                    a.Tooltip?.toLowerCase().includes(this.search_metrics.toLowerCase()) ||
                    a.Description?.toLowerCase().includes(this.search_metrics.toLowerCase()) ||
                    a.SourceType?.toLowerCase().includes(this.search_metrics.toLowerCase())
                ).toSorted((a, b) => this.byField(a, b, 'Title'));
            },

            disableDateGroupInterval: function() {
                return !this.groupingColumns.some(a => a.SourceType == 'dateColumn' && this.groupby.some(b => b == a.ColId));
            },
            selectedDateColumn: function() {
                const c = this.groupingColumns.find(a => a.SourceType == 'dateColumn' && this.groupby.some(b => b == a.ColId));
                if (c)
                    return c;
                else
                    return false;
            },

            getPivotOn: function() {
                console.log(`getPivotOn:`);
                console.log(JSON.stringify(this.groupby,null,3));

                if (this.groupby.length < 2)
                    return null;

                const groupby = this.groupby.map(g => this.groupingColumns.find(c => c.ColId == g));

                const g = groupby[groupby.length-1];
                if (g.SourceType != 'dateColumn')
                    return { ColId: g.ColId, Title: g.Title };

                return null;
            },

            serverPaginationMode: {
                get() { return common.serverPaginationMode; },
                set(value) { common.serverPaginationMode = value; }
            },
            pageSize: {
                get() { return common.pageSize; },
                set(value) { common.pageSize = value; }
            },
            presortSize: {
                get() { return common.presortSize; },
                set(value) { common.presortSize = value; }
            },
        },
        methods: {
            loadMetricFilters() {
                for (let i = 0; i < this.metricDataTypes.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricDataTypes[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.metricDataTypes_selection.push(i);

                for (let i = 0; i < this.metricCategories.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricCategories[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.metricCategory_selection.push(i);

                if (this.debugMode) {
                    for (let i = 0; i < this.metricSources.length; i++)
                        if (appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricSources[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                            this.metricSources_selection.push(i);
                }
                else
                    this.metricSources_selection = this.metricSources.map((a, i) => i);

                this.anyMetricfilters =
                    this.metricDataTypes_selection.length < this.metricDataTypes.length ||
                    this.metricCategory_selection.length < this.metricCategories.length ||
                    this.metricSources_selection.length < this.metricSources.length;
            },
            loadGroupFilters() {
                for (let i = 0; i < this.groupDataTypes.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupDataTypes[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.groupDataTypes_selection.push(i);

                for (let i = 0; i < this.groupCategories.length; i++)
                    if (appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupCategories[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                        this.groupCategory_selection.push(i);

                if (this.debugMode) {
                    for (let i = 0; i < this.groupSources.length; i++)
                        if (appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupSources[i].text + '-' + (this.source ? `-${this.source}` : '')) != 'hidden')
                            this.groupSources_selection.push(i);
                }
                else
                    this.groupSources_selection = this.groupSources.map((a, i) => i);

                this.anyGroupfilters =
                    this.groupDataTypes_selection.length < this.groupDataTypes.length ||
                    this.groupCategory_selection.length < this.groupCategories.length ||
                    this.groupSources_selection.length < this.groupSources.length;
            },
            async refreshCategories() {
                this.reportCategories = await common.loadCategories();
            },

            async categoryActions(){
                utils.executeAndCompileAllActions(this.control_category_actions, {}, this);
            },
            saveMetricFilters() {
                for (let i = 0; i < this.metricDataTypes.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricDataTypes[i].text + '-' + (this.source ? `-${this.source}` : ''), this.metricDataTypes_selection.includes(i) ? null : 'hidden');

                for (let i = 0; i < this.metricCategories.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricCategories[i].text + '-' + (this.source ? `-${this.source}` : ''), this.metricCategory_selection.includes(i) ? null : 'hidden');

                if (this.metricSources)
                    for (let i = 0; i < this.metricSources.length; i++)
                        appSettings.LocalStorage(this.storageKey + '-metricFilters-type:' + this.metricSources[i].text + '-' + (this.source ? `-${this.source}` : ''), this.metricSources_selection.includes(i) ? null : 'hidden');
            },
            saveGroupFilters() {
                for (let i = 0; i < this.groupDataTypes.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupDataTypes[i].text + '-' + (this.source ? `-${this.source}` : ''), this.groupDataTypes_selection.includes(i) ? null : 'hidden');

                for (let i = 0; i < this.groupCategories.length; i++)
                    appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupCategories[i].text + '-' + (this.source ? `-${this.source}` : ''), this.groupCategory_selection.includes(i) ? null : 'hidden');

                if (this.groupSources)
                    for (let i = 0; i < this.groupSources.length; i++)
                        appSettings.LocalStorage(this.storageKey + '-groupFilters-type:' + this.groupSources[i].text + '-' + (this.source ? `-${this.source}` : ''), this.groupSources_selection.includes(i) ? null : 'hidden');
            },
            isGroupVisible(category) {
                const selected = this.groupCategory_selection.map(i => this.groupCategories[i].category);
                return selected.some(a => a == category.category);
            },
            isMetricGroupVisible(category) {
                const selected = this.metricCategory_selection.map(i => this.metricCategories[i].category);
                return selected.some(a => a == category.category);
            },
            //getMetricTitle(metric) {
            //    if ((metric.MetricAggs.Type == 'function' || metric.MetricAggs.Type == 'filter' || metric.MetricAggs.Type == 'script' || metric.MetricAggs.Type == 'filter_script') && metric.MetricAggs.FunctionList.length > 1)
            //        return metric.Title;
            //    else if ((metric.MetricAggs.Type == 'function' || metric.MetricAggs.Type == 'filter' || metric.MetricAggs.Type == 'script' || metric.MetricAggs.Type == 'filter_script') && metric.MetricAggs.FunctionList.length == 1) {
            //        const col = this.columnLookup[metric.ColId];
            //        if (col)
            //            return `${metric.Title} (${common.formatMetric({ headerName: col.Title, type: col.SourceType, aggFunc: metric.MetricAggs.FunctionList[0] })})`;
            //        else
            //            return metric.Title;
            //    }
            //    else if (metric.MetricAggs.Type == 'bucket_script')
            //        return metric.Title;
            //    else
            //        return 'N/A';
            //},
            getGroupColumns(category) {
                const visibletypes = this.groupDataTypes_selection.flatMap(i => this.groupDataTypes[i].types);
                const visiblesources = this.groupSources_selection.map(i => this.groupSources[i].source);
                const cols = this.column_list
                    .filter(a => a.ColumnUse.Group && !a.CompoundFilter && a.Category == category.category && visibletypes.includes(a.SourceType) && visiblesources.some(s => a.Sources.includes(s)))
                    .toSorted((a, b) => common.byField(a, b, 'Title'));
                return cols;
            },
            getMetricColumns(category) {
                const visibletypes = this.metricDataTypes_selection.flatMap(i => this.metricDataTypes[i].types);
                const visiblesources = this.metricSources_selection.map(i => this.metricSources[i].source);
                const cols = this.column_list
                    .filter(a => a.ColumnUse.Metric && !a.CompoundFilter && a.Category == category.category && visibletypes.includes(a.SourceType) && a.Sources.some(s => visiblesources.includes(s)))
                    .toSorted((a, b) => common.byField(common.getMetricTitle(a, this.columnLookup), common.getMetricTitle(b, this.columnLookup)));
                return cols;
            },
            setMetricGroupExpansion(category,value){
                Vue.set(this.metricgroup_expanded, category.category, value);
                //console.log(`setMetricGroupExpansion(${category.category},${value})`);
                //console.log(JSON.stringify(this.metricgroup_expanded,null,3));
            },

            filterschanged(filters, colId) {
                this.$emit('filterschanged', filters, colId);
            },
            clearAllFilters() {
                this.EventBus.$emit('ClearAllReportFilters');
            },

            refreshtool: function() {
            },
            refreshReport: function() {
                console.log('refreshReport groupby:');
                console.log(JSON.stringify(this.groupby,null,3));

                let groupby = this.groupby.map(g => this.groupingColumns.find(c => c.ColId == g)).map(g => ({
                        colId: g.ColId,
                        ...this.groupbysettings[g.ColId],
                    }));

                // Remove the pivot column from the group by
                if (this.pivoton) // && groupby[groupby.length-1].colId == this.pivoton)
                    groupby.splice(groupby.length-1,1);

                const report = {
                    datetime_interval: this.interval_choice,
                    groupby: groupby,
                    metrics: this.metrics.map(m => ({
                        colId: m.ColId,
                        function: m.calculation
                    })),
                    pivoton: this.pivoton ? this.getPivotOn?.ColId : null, // this.pivoton,
                };
                this.notifychange(report);
                //this.$emit('change', report);
            },
            byField(a, b, field) {
                return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            },

            onDrop(result) {
                // Ex: {"removedIndex":2,"addedIndex":0}
                const item = this.groupby[result.removedIndex];

                this.groupby.splice(result.removedIndex, 1);
                this.groupby.splice(result.addedIndex, 0, item);

                this.notifyGroupOrder();
            },
            notifyGroupOrder() {
                const args = this.groupby.map((a, i) => ({ colId: a == 'datetime' ? 'starttime' : a, toIndex: i }));

                //console.log(`$emit groupbyordered: ${JSON.stringify(args)}`);

                this.$emit('groupbyordered', args);
            },

            addMetric(item, calc) {
                this.metrics.push({
                    id: `${calc}(${item.ColId})`,
                    calculation: calc,
                    ...item,
                });
                console.log(`addMetric(${item.ColId}, ${calc})`);
            },
            removeMetric(item, func) {
                if (!func) {
                    const idx = this.metrics.findIndex(a => a.id == item.id);
                    if (idx >= 0)
                        this.metrics.splice(idx, 1);
                }
                else {
                    const idx = this.metrics.findIndex(a => a.ColId == item.ColId && a.calculation == func);
                    if (idx >= 0)
                        this.metrics.splice(idx, 1);
                }
            },
            isDisabledMetric(item, calc) {
                return this.metrics.some(a => a.id == `${calc}(${item.ColId})`);
            },
            isMetricDisabledSource(item) {
                //if (item.ColId == 'datetime' || item.ColId == 'starttime') {
                //    console.log(`${item.ColId}.Sources: ${JSON.stringify(item.Sources)}; groupsources_selected:${JSON.stringify(this.groupsources_selected)}`);
                //    //debugger;
                //}
                if (item.MetricAggs.Type == 'placeholder' && this.metrics.length == 0)
                    return true;

                return !this.groupsources_selected.some(a => item.Sources.includes(a));
            },
            isGroupDisabledSource(item) {
                if (this.groupby.length >= 4 && !this.groupby.includes(item.ColId))
                    return true;

                if (this.metrics.length < 1 || !item.Sources)
                    return false;

                // If the groupby column's sources doesn't have at least one that is part of the metric sources, it is disabled
                return !item.Sources.some(a => this.metricsources_selected.includes(a));
            },
            getMetricHelp(item) {
                return item.Tooltip || item.Title;
            },
            showHelp(item) {
                this.help.item = item;
                this.help.visible = true;
            },
            showMetricFilter(item) {
                this.filter.item = item;
                this.filter.visible = true;
            },
            getMetricItemIcon(item) {
                const mlist = this.metrics.filter(a => a.ColId == item.ColId);
                if (mlist.length > 0) {
                    switch (item.MetricAggs.Type)
                    {
                        case 'function':
                        case 'filter':
                        case 'script':
                        case 'filter_script':
                            if (item.MetricAggs.FunctionList.every(a => mlist.some(f => f.calculation == a)))
                                return 'mdi-checkbox-marked';
                            break;

                        case 'bucket_script':
                            return 'mdi-checkbox-marked';
                    }
                    return 'mdi-checkbox-marked';
                }
                else
                    return 'mdi-checkbox-blank-outline';
            },
            isMetricInUse(item) {
                return this.metrics.some(a => a.ColId == item.ColId);
            },

            toggleGroupby(item) {
                const idx = this.groupby.indexOf(item.ColId);
                if (idx >= 0)
                    this.groupby.splice(idx, 1);
                else
                    this.groupby.push(item.ColId);
            },
            setgroupbysettings(item) {
                console.log('setgroupsettings:');
                console.log(JSON.stringify(item,null,3));

                this.groupbysettings[item.colId] = item;
                this.refreshReport();
            },

            changeColumnGroups(groups) {
                this.columngroups_selection = groups;
            },
            changeMetricGroups(groups) {
                this.columnmetrics_selection = groups;
            },
            changeInterval(interval) {
                this.interval_selection = interval;
            },

            applyDateRangeHandler(range) {
                this.settings.startDate = range.startDate;
                this.settings.endDate = range.endDate;

                this.$emit('daterangechanged');
                //this.System.LocalStorage(this.storageKey + '-daterange', `${common.startDate} - ${common.endDate}`);

                this.refreshReport();
            },

        }
    }
</script>

<style scoped>
    .standard-input-field {
        background-color: white;
    }

    .hidden-element {
        visibility: hidden;
    }

    .columns-two {
        column-count: 2;
    }

    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {

    }
    .metric_inuse {
        color: cornflowerblue;
    }
    .metric_usedup {
        color: silver;
    }
    .draggable-item {
        cursor: grab;
    }
    .expandIconSpoof {
        transition: transform .3s ease-in-out !important; /* spoofing expansion pannel action icon. Needed to allow positioning of "Clear All" button */
    }
    .expandIconSpoof.rotate {
        transform: rotate(-180deg); /* spoofing expansion pannel action icon. Needed to allow positioning of "Clear All" button */
    }
    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>


<style>
    .left-pad-4 {
        padding-left: 4px;
    }
    .v-expansion-panel-header__icon{
        margin-left: 0 !important;
    }
</style>