import Vue from 'vue';
import BaseComponent from './BaseComponentMixin.jsx';
import utils from '../../Shared/utils.jsx';
import Calendar from '@/Application/components/vuecontrols/calendar.vue';
import careHelpfulFunctions from '@/Shared/careHelpfulFunctions';

import common from './vuecontrols/advancedHistory/common.js';

Vue.component('calendar-picker', {
    mixins: [BaseComponent],
    components: {
        Calendar
    },
    data: function () {
        return {
            defaultTime: null,
            startDate: null,
            endDate: null,
        }
    },
    created() {
        this.startDate = common.getRelativeDateFromFilter('now-1y/d');
        this.endDate = common.getRelativeDateFromFilter('now', true);

        this.defaultTime = JSON.stringify({ startDate: this.startDate, endDate: this.endDate });
    },
    computed: {
        styles() {
            return {
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingTop: "3px",
                //marginLeft: "8px",
                ...utils.resolveStyleHints(this.styleHints, this),
            };
        },
        width() {
            let theWidth = "430px";
            if (!this.widthEval && this.controlData.Width)
                this.widthEval = utils.compile(this, this.controlData.Width);
            if (this.widthEval) theWidth = utils.evaluate(this.widthEval, this);
            return theWidth;
        },
        isActive() {
            if (!this.isactiveEval && this.controlData.EnabledExpression)
                this.isactiveEval = utils.compileExpression(this, this.controlData.EnabledExpression);
            if (this.isactiveEval)
                return utils.evaluate(this.isactiveEval, this);
            return true;
        },
        StartDate() {
            return this.startDate;
        },
        EndDate() {
            return this.endDate;
        },
    },
    methods: {
        async preRenderComplete() {
            this.finishRenderHandler(this);
        },
        async applyDateRangeHandler(range) {
            this.startDate = range.startDate;
            this.endDate = range.endDate;

            await utils.executeAndCompileAllActions(this.controlData.Actions, { StartDate: this.startDate, EndDate: this.EndDate }, this);
        },
    },
    render(h) {
        if(!this.todisplay)
            return null;

        try {
            let cal = (
                <Calendar
                    myclass="mb-1"
                    value={this.defaultTime}
                    width={this.width}
                    label="Select Date Range"
                    isactive={this.isActive}
                    //iconOnly={!this.isActive}
                    activeicon="mdi-calendar"
                    inactiveicon="mdi-calendar-remove"
                    activecolor=""
                    inactivecolor="grey lighten-2"
                    on-change={this.applyDateRangeHandler}>
                </Calendar>
            );

            return (
                <div
                    class={{ "c-CalendarPicker": true, [`c-name-${this.name || "unnamed"}`]: true }}
                    v-show={this.isvisible}
                    style={this.styles}
                >
                    {cal}
                </div>
            );
        }
        catch (e) {
            utils.error('CalendarPicker Render failed', e);
            return <div>CalendarPicker Failed to Render {e}</div>;
        }
    }
});